import StockHistoryModal from '../../../../../../Components/Common/Modals/StockHistoryModal'
import Table from '../../../../../../Components/Common/Table'
import {useSKUTable} from '../../../../hooks/useSKUTable'

const SKUTable = () => {
  const {
    historyModalState,
    handleModalClose,
    productCombination,
    skuTableConfig
  } = useSKUTable()

  return (
    <>
      <Table
        tableInfo={productCombination}
        tableColumns={skuTableConfig}
        footer={false}
      />
      <StockHistoryModal
        isOpened={historyModalState.isOpen}
        item={historyModalState.item}
        toggle={handleModalClose}
      />
    </>
  )
}

export default SKUTable
