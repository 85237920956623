import React from 'react'

import BaseButton from '../../../Components/Common/Buttons/BaseButton'
import SelectInput from '../../../Components/Common/Inputs/SelectInput/SelectInput'
import TextInput from '../../../Components/Common/Inputs/TextInput/TextInput'

const Filters = ({filters, setFilters, filterOptions, onClear}) => {
  const clearSelectInput = field => {
    setFilters(prev => ({...prev, [field]: []}))
  }

  return (
    <div className="transfers-filter-wrapper">
      <div className="filter-container">
        <div className="filter-item">
          <TextInput
            label="Search "
            placeholder="Search"
            value={filters.search}
            onChange={e =>
              setFilters(prev => ({...prev, search: e.target.value}))
            }
            name="search"
            id="search"
          />
        </div>
        <div className="filter-item">
          <SelectInput
            label=" Client"
            placeholder="Choose Client"
            value={filters.b2bClientId}
            onChange={value =>
              setFilters(prev => ({...prev, b2bClientId: value}))
            }
            options={
              filterOptions.b2bClients?.map(client => ({
                label: client.name,
                value: client.b2bClientId
              })) || []
            }
            searchable
            clearable
            useSimpleOptions
            clearFunction={() => clearSelectInput('b2bClientId')}
          />
        </div>
        <div className="filter-item">
          <TextInput
            label="Purchase Order"
            placeholder="Purchase Order"
            value={filters.purchaseOrder}
            onChange={e =>
              setFilters(prev => ({...prev, purchaseOrder: e.target.value}))
            }
            name="purchaseOrder"
            id="purchaseOrder"
          />
        </div>
        <div className="filter-item">
          <TextInput
            label="B2B Order Number"
            placeholder="B2B Order Number"
            value={filters.b2bOrderNumber}
            onChange={e =>
              setFilters(prev => ({...prev, b2bOrderNumber: e.target.value}))
            }
            name="b2bOrderNumber"
            id="b2bOrderNumber"
          />
        </div>
        <BaseButton
          text="Clear"
          onClick={onClear}
          type="secondary"
          width="small"
          icon="columnFilterIcon"
          className="selected"
        />
      </div>
    </div>
  )
}

export default Filters
