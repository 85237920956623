import client from '../config/AxiosConfig'

export const GetB2BProducts = async (page, pageSize, filters) =>
  client.get(
    `/Products/getListedProducts?IsB2C=false&Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetB2CProducts = async (page, pageSize, filters) =>
  client.get(
    `/Products/getListedProducts?IsB2C=true&Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetFullProductList = async (page, pageSize, filters) =>
  client.get(
    `/Products/getListedProducts?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetRawMaterials = async (page, pageSize, filters) =>
  client.get(
    `/Products/getListedRawMaterials?Page=${page}&ItemsPerPage=${pageSize}${filters}`
  )

export const GetProduct = async id =>
  client.get(`/Products/ProductViewDetail/${id}`)

export const GetRawMaterial = async id =>
  client.get(`/Products/RawMaterialviewDetail/${id}`)

export const EditRawMateria = async (id, data) =>
  client.put(`/Products/putRawMaterial/${id}`, data)

export const EditProductProperties = async (id, data) =>
  client.put(`/Products/putProperties/${id}`, data)

export const EditProductPrices = async (id, data) =>
  client.put(`/Products/putPrice/${id}`, data)

export const EditProductComposition = async (id, data) =>
  client.put(`/Products/putComposition/${id}`, data)

export const EditProductOnlineStore = async (id, data) =>
  client.put(`/Products/putOnlineStore/${id}`, data)

export const SaveMeasures = async (id, data) =>
  client.put(`/Products/putMeasure/${id}`, data)

export const SaveMeasureImage = async (id, data) =>
  client.put(`/Products/${id}/putProductMeasureImage`, data)

export const SaveProductImage = async (id, data) =>
  client.post(`/Products/${id}/PostProductImage`, data)

export const DeleteProductImage = async (id, image) =>
  client.delete(`/Products/${id}/deleteProductImage/${image}`)

export const ChangeCoverImage = async (id, image) =>
  client.put(`/Products/${id}/chooseProductImageCover/${image}`)

export const ChangeFittingImage = async (id, image) =>
  client.put(`/Products/${id}/ChooseProductImageFitting/${image}`)

export const ManageImageColors = async (id, image, colors) =>
  client.put(`/Products/${id}/PutProductImageColors/${image}`, colors)

export const EditSKU = async (id, data) =>
  client.post(`/Products/postSKU/${id}`, data)

export const EditBaseSKU = async (id, data) =>
  client.put(`/Products/putBaseSKU/${id}`, data)

export const DuplicateProductInformation = async (id, data) =>
  client.put(`/Products/duplicateFromExisting/${id}`, data)

export const GetRelatedProducts = async () =>
  client.get('/Products/getRelatedProducts')

export const GetEDProducts = async id =>
  client.get(`/Products/${id}/getEDProducts`)

export const UploadProductFile = async (id, data) =>
  client.put(`/Products/PutProductFile/${id}`, data)

export const DeleteProductFile = async id =>
  client.delete(`/Products/deleteProductFile/${id}`)

export const GetServices = async (page, pageSize, filters) =>
  client.get(
    `/Services/ListServices?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  )

export const GetService = async id =>
  client.get(`/Services/ServiceViewDetail/${id}`)

export const DeleteService = async id =>
  client.delete(`/Services/DeleteService/${id}`)

export const CreateService = async data =>
  client.post('/Services/CreateNewService', data)

export const EditService = async (id, data) =>
  client.put(`/Services/PutServiceViewDetail/${id}`, data)

export const GetAvailableProductRawMaterials = async (
  page,
  pageSize,
  filters,
  list
) =>
  client.post(
    `/Products/getListedRawMaterialsProduct?Page=${page}&ItemsPerPage=${pageSize}${filters}`,
    list
  )
