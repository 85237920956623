import {useState} from 'react'

import {Popover} from '@mantine/core'

import DatePickerComponent from '../DateInput/DatePickerComponent'

const DropDownDate = ({children, onChange, value, top = 5, isInTable}) => {
  const [opened, setOpened] = useState(false)

  const handleClose = () => {
    if (!value || !(value[0] && value[1])) {
      onChange([null, null])
    }
  }

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      onClose={handleClose}
      position={isInTable ? 'bottom-end' : 'bottom-start'}
      offset={top}
      width="auto"
      withinPortal={true}>
      <Popover.Target>
        <div onClick={() => setOpened(prev => !prev)}>{children}</div>
      </Popover.Target>

      <Popover.Dropdown style={{padding: 0, zIndex: 99999, marginTop: '1px'}}>
        <div style={{padding: 5, minWidth: 200}}>
          <DatePickerComponent
            type="range"
            value={value}
            onChange={date_range => {
              onChange(date_range)

              if (date_range[0] && date_range[1]) {
                setOpened(false)
              }
            }}
          />
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}

export default DropDownDate
