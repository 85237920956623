import React from 'react'

import Placeholder from '../../../../../assets/images/placeholder.jpg'

const index = ({data}) => {
  return (
    <div className="d-flex">
      <div className="h-full d-flex align-items-center">
        <img
          style={{minWidth: '40px', maxHeight: '40px'}}
          src={data.image ? data.image : Placeholder}
          alt="Product"
        />
      </div>
      <div className="h-full m-2">
        <div
          style={{minWidth: '150px'}}
          className="h-full d-flex align-items-center table-image-description">
          <span className="heading_4">{data.productName}</span>
        </div>
        <div>
          {' '}
          <span className="heading_5">{data.referencecode}</span>
        </div>
      </div>
    </div>
  )
}

export default index
