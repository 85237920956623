import {createSlice} from '@reduxjs/toolkit'

export const initialState = {
  user: {},
  error: '', // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
  userEmail: '' // to pass email to forgot email page
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    apiError: (state, action) => {
      state.error = action.payload.data
      state.loading = false
      state.isUserLogout = false
      state.errorMsg = true
      return state
    },
    loginSuccess(state, action) {
      state.user = action.payload
      state.loading = false
      state.errorMsg = false
    },
    // eslint-disable-next-line no-unused-vars
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
    },
    reset_login_flag(state) {
      state.error = null
      state.loading = false
      state.errorMsg = false
    },
    start_loading(state) {
      state.error = null
      state.loading = true
      state.errorMsg = false
    },
    end_loading(state) {
      state.error = null
      state.loading = false
      state.errorMsg = false
    },
    saveUserEmail(state, action) {
      state.userEmail = action.payload
    }
  }
})

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  start_loading,
  end_loading,
  saveUserEmail
} = loginSlice.actions

export default loginSlice.reducer
