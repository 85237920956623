export const ProductTranslationLanguages = [
  {
    languageId: 1,
    name: 'Portuguese',
    abrev: 'pt'
  },
  {
    languageId: 2,
    name: 'English',
    abrev: 'en'
  },
  {
    languageId: 3,
    name: 'French',
    abrev: 'fr'
  },
  {
    languageId: 4,
    name: 'German',
    abrev: 'de'
  },
  {
    languageId: 5,
    name: 'Spanish',
    abrev: 'es'
  },
  {
    languageId: 6,
    name: 'Dutch',
    abrev: 'nl'
  }
]
