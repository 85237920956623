import React, {useEffect, useState} from 'react'

import TextInput from '../../Components/Common/Inputs/TextInput/TextInput'
import Wizards from '../../Components/Common/Wizards/Wizards'

const Placeholder = () => {
  const [wizards, setWizards] = useState(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const wizardItems = [
    {id: 1, title: 'General'},
    {id: 2, title: 'Description'},
    {id: 3, title: 'Finish'}
  ]

  return (
    <div className="page-content">
      <div style={{padding: 20, backgroundColor: 'white', margin: 20}}>
        <Wizards
          loading={loading}
          wizards={wizards}
          setWizards={setWizards}
          items={wizardItems}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: 20
          }}>
          <div style={{width: '100%'}}>
            <TextInput
              label="Email"
              name="Email"
              id="Email"
              placeholder="Enter email"
              type="text"
              loading={loading}
            />
            <TextInput
              label="UserName"
              name="UserName"
              id="UserName"
              placeholder="Enter user Name"
              type="text"
              loading={loading}
            />
          </div>
          <div style={{width: '100%'}}>
            <TextInput
              label="Password"
              name="Password"
              id="Password"
              placeholder="Enter password"
              type="text"
              loading={loading}
            />
            <TextInput
              label="Confirm password"
              name="Confirm password"
              id="Confirm password"
              placeholder="Enter confirm password"
              type="text"
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          padding: 20,
          gap: 10,
          display: 'flex',
          flexDirection: 'column'
        }}>
        width
        <div className="placeholder-sm w-25" />
        <div className="placeholder-sm w-50" />
        <div className="placeholder-sm w-75" />
        <div className="placeholder-sm w-100" />
        height
        <div className="placeholder-lg" />
        <div className="placeholder-md" />
        <div className="placeholder-sm" />
        <div className="placeholder-xs" />
        colors
        <div className="placeholder-sm placeholder-color-light" />
        <div className="placeholder-sm placeholder-color-medium" />
        <div className="placeholder-sm placeholder-color-dark" />
        <div className="placeholder-sm placeholder-color-blue" />
        <div className="placeholder-sm placeholder-color-green" />
        <div className="placeholder-sm placeholder-color-yellow" />
        <div className="placeholder-sm placeholder-color-red" />
      </div>
    </div>
  )
}

export default Placeholder
