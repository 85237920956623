import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Imagem12 from '../../../assets/imagesNew/alerts/profilebackground.png'

const MySwal = withReactContent(Swal)

const myTemplate = (image, title, description) => `
<div style="text-align: center;margin:15">
<img src="${image}" alt="Order Created Image" style="width: 100px; height: 100px;">
</div>
<swal-title class="text-primary fontsize-title">
${title}
</swal-title>
<p class="heading_6 sweet-font-opacity" >
${description}
</p>
<swal-param name="allowEscapeKey" value="false" />
<swal-param
name="customClass"
value='{ "popup": "my-popup" }' />
<swal-function-param
name="didOpen" 
value="popup => console.log(popup)" />   
`

const myTemplateNotifications = (image, title, description, notifications) => `
<div style="text-align: center;margin:15; display:flex;justify-content:center">
<div class="wrapper">
<div class="badge badge-alert bg-success">${notifications}</div>
<img src="https://picsum.photos/72" alt="Order Created Image" style="width: 72px; height: 72px; position: absolute;margin-top:14px; margin-left:14px; border:1 ; border-radius:100%; z-index: 1">
<img src="${image}" alt="Order Created Image" style="width: 100px; height: 100px;">
</div>
</div>
</div>
<swal-title class="text-primary fontsize-title">
${title}
</swal-title>
<p class="text-secondary fs-6 m-1 fontsize-description" >
${description}
</p>
<swal-param name="allowEscapeKey" value="false" />
<swal-param
name="customClass"
value='{ "popup": "my-popup" }' />
<swal-function-param
name="didOpen"
value="popup => console.log(popup)" />
`

export const showAlert = data => {
  const {
    image,
    title,
    description,
    footer,
    footerCss,
    closeButtonCss,
    confirmButtonText,
    cancelButtonText,
    confirmButtonCss,
    showCancelButton,
    showConfirmButton,
    showCloseButton,
    cancelButtonCss
  } = data

  return new Promise((resolve, reject) => {
    MySwal.fire({
      html: myTemplate(image, title, description),
      confirmButtonText: confirmButtonText,
      reverseButtons: true,
      focusCancel: false,
      focusConfirm: false,
      showCloseButton: showCloseButton,
      showCancelButton: showCancelButton,
      showConfirmButton: showConfirmButton,
      footer: footer,
      closeButtonHtml: '<i class="mdi mdi-close sweet-cross-alert"></i>',
      cancelButtonText: cancelButtonText,
      customClass: {
        actions: 'my-actions',
        closeButton: closeButtonCss,
        cancelButton: cancelButtonCss,
        confirmButton: confirmButtonCss,
        footer: footerCss
      },

      willOpen: () => {
        const actions = document.querySelector('.swal2-actions')
        const cancelButton = document.querySelector('.swal2-cancel')
        const confirmButton = document.querySelector('.swal2-confirm')
        cancelButton.style.width = '100px' // Adjust width as needed
        actions.style.width = '100%' // Adjust width as needed
        confirmButton.style.width = '100px' // Adjust width as needed
      }
    })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const showNotificationAlert = (notifications, name) => {
  const getImageAndTitle = () => {
    return {
      image: Imagem12,
      notification: notifications,
      title: `Welcome <span class="text-primary">${name}</span> !`,
      description: `You have <span class="text-success">${notifications}</span> Notifications`
    }
  }

  const {image, title, description} = getImageAndTitle()

  return new Promise((resolve, reject) => {
    MySwal.fire({
      html: myTemplateNotifications(image, title, description, notifications),
      confirmButtonText: 'Show me',
      denyButtonText: 'No',
      showCloseButton: true,
      closeButtonHtml: '<i class="mdi mdi-close sweet-cross-alert"></i>',
      customClass: {
        actions: 'my-actions',
        closeButton: ' Nofocus close-button',
        confirmButton: 'bg-info border-0 Nofocus fontsize-buttons'
      }
    })
      .then(result => {
        resolve(result)
      })
      .catch(error => {
        reject(error)
      })
  })
}
