export const GetAvailableLanguages = () => ['en', 'pt']

export const Languages = [
  {code: 'en', name: 'Inglês'},
  {code: 'pt', name: 'Português'}
]

export const GetTranslationDescription = code =>
  Languages.find(x => x.code === code)?.name

export const GetTranslationNameFromArray = (value, languageID) => {
  if (!value || !Array.isArray(value) || value.length === 0) return ''
  const found = value.find(
    elem => elem.languageID === languageID || elem.languageId === languageID
  )
  if (found) return found.name
  const en = value.find(elem => elem.languageID === 2 || elem.languageId === 2)
  if (en) return en
  return value[0].name
}
