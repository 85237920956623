import React from 'react'

import {Button} from 'reactstrap'

import Icon from '../../icon'

const BaseButtonGroup = ({disabled, icon, options}) => {
  const handleDisabled = () => {
    if (disabled) {
      return 'base-btn-disabled'
    }
    return ''
  }

  const handleIcon = () => {
    if (icon) {
      return 'base-btn-icon'
    }
    return ''
  }

  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {options.map((button, index) => (
        <Button
          onClick={button.onClick}
          style={{
            height: 37,
            minWidth: 78,
            borderTopLeftRadius: index === 0 ? 4 : 0,
            borderBottomLeftRadius: index === 0 ? 4 : 0,
            borderTopRightRadius: index === options?.length - 1 ? 4 : 0,
            borderBottomRightRadius: index === options?.length - 1 ? 4 : 0,
            borderRightWidth: index !== options?.length - 1 ? 0 : 1
          }}
          key={index}
          disabled={disabled}
          className={`
            base-btn-secondary-black
            base-btn-icon
            ${handleDisabled()} 
            ${handleIcon()}
          `}>
          {button.icon && (
            <Icon style={{marginRight: 3}} icon={button.icon} size={[16, 16]} />
          )}
          {button.text}
        </Button>
      ))}
    </div>
  )
}

export default BaseButtonGroup
