import React, {useEffect, useRef, useState} from 'react'

import Placeholder from '../../../assets/images/placeholder.jpg'
import ColorBadge from '../../Common/Badges/ColorBadge'
import PaginationComponent from '../PaginationComponent'

const TableThumbs = ({
  tableInfo,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  totalItems,
  setRowsPerPage,
  headerHeight
}) => {
  const totalPages = Math.ceil(totalItems / rowsPerPage)
  const [hovered, setHovered] = useState()
  const extraDivRef = useRef(null)
  const [extraDiv, setExtraDiv] = useState()
  const onPageChange = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const handleRowsPerPageChange = value => {
    setRowsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing rows per page
  }

  useEffect(() => {
    if (extraDivRef.current) {
      const height = extraDivRef.current.getBoundingClientRect().height
      setExtraDiv(height)
    }
  }, [extraDiv])

  useEffect(() => {
    if (extraDivRef.current) {
      if (hovered !== undefined) {
        extraDivRef.current.style.height =
          extraDivRef.current.scrollHeight + 'px'
      } else {
        extraDivRef.current.style.height = '0'
      }
    }
  }, [hovered])

  const dynamicMaxHeight = `calc(100vh - (${headerHeight}px + 220px))`

  return (
    <div
      style={{
        width: '100%',
        margin: 'auto'
      }}>
      <div
        className="thumbs-table-wrapper"
        style={{
          paddingBottom: 262 + 18,
          // maxHeight: "calc(100vh - 290px)",
          maxHeight: dynamicMaxHeight,
          overflowY: 'auto'
        }}>
        {tableInfo.length === 0 && <div>No options available</div>}
        {tableInfo.map((item, index) => {
          return (
            <div
              className={
                hovered === index ? 'thumbs-card-hovered' : 'thumbs-card'
              }
              key={index}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(undefined)}>
              <div>
                <div
                  className="heading_4"
                  style={{lineHeight: 15, maxHeight: 32, overflow: 'hidden'}}>
                  {item.productName}
                </div>
                <div style={{marginTop: 3}} className="heading_6">
                  {item.referencecode}
                </div>
              </div>
              <img
                style={{
                  width: '100%',
                  height: 225,
                  objectFit: 'contain',
                  backgroundColor: 'RGB(248, 248, 248)'
                }}
                src={item.image ? item.image : Placeholder}
                alt="Product"
              />
              {hovered === index && (
                <div className="thumbs-extra-card" ref={extraDivRef}>
                  <div className="bodyText_4">Manufactoring</div>
                  <div className="bodyText_9">{item.type}</div>
                  <div style={{marginTop: 8}} className="bodyText_4">
                    Cost Price
                  </div>
                  <div className="bodyText_9">15.100 Unit</div>
                  <div style={{marginTop: 8}} className="bodyText_4">
                    Stock
                  </div>
                  <div className="bodyText_9">15.100 Unit</div>
                  <div style={{marginTop: 8}} className="bodyText_4">
                    Sales price
                  </div>
                  <div className="bodyText_9">20,10€</div>
                  <div style={{marginTop: 8}} className="bodyText_4">
                    Gross Margin
                  </div>
                  <div className="bodyText_9">35,00%</div>
                  <div className="table-thumb-divider" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}>
                    <div className="bodyText_4">Colors</div>
                    <ColorBadge color="#DEDEDE" />
                  </div>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {tableInfo.length > 0 && (
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          products={tableInfo}
          totalItems={totalItems}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </div>
  )
}

export default TableThumbs
