import {GetAuthUser} from '../../../infra/requests/BaseRequests'
import {profileError, profileSuccess, resetProfileFlagChange} from './reducer'

export const getProfile = () => async dispatch => {
  try {
    const response = await GetAuthUser()

    if (response.success) {
      dispatch(profileSuccess(response))
    }
  } catch (error) {
    dispatch(profileError(error))
  }
}

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange()
    return response
  } catch (error) {
    return error
  }
}
