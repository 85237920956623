import React, {useEffect, useState} from 'react'

import {useSelector} from 'react-redux'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'

import i18n from '../../i18n'

const LanguageDropdown = () => {
  const [selectedLang, setSelectedLang] = useState('')
  const languagesAvailable =
    useSelector(state => state.Info.data.languagesAvailable) || []

  const [isLanguageDropdown, setIsLanguageDropdown] = useState(false)

  useEffect(() => {
    const currentLanguage = JSON.parse(localStorage.getItem('I18N_LANGUAGE'))
    setSelectedLang(currentLanguage?.abrev || 'en')
  }, [])

  const updateUrlWithLanguage = languageId => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const newParams = new URLSearchParams()

    newParams.set('languageId', languageId.toString())
    params.forEach((value, key) => {
      if (key !== 'languageId') {
        newParams.set(key, value)
      }
    })

    const newUrl = `${url.origin}${url.pathname}?${newParams.toString()}${url.hash}`
    window.history.pushState({}, '', newUrl)
  }

  const changeLanguageAction = lang => {
    if (lang?.abrev) {
      const languageData = {abrev: lang.abrev, id: lang.languageId}
      i18n.changeLanguage(lang.abrev)
      localStorage.setItem('I18N_LANGUAGE', JSON.stringify(languageData))
      setSelectedLang(lang.abrev)
      updateUrlWithLanguage(lang.languageId)
    }
  }

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdown(!isLanguageDropdown)
  }

  const selectedLanguage = languagesAvailable.find(
    lang => lang.abrev === selectedLang
  ) ||
    languagesAvailable[0] || {abrev: 'en', name: 'English'}

  if (!languagesAvailable.length) return null

  return (
    <div>
      <Dropdown
        isOpen={isLanguageDropdown}
        toggle={toggleLanguageDropdown}
        className="ms-1 py-2 topbar-head-dropdown header-item">
        <DropdownToggle
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          tag="button">
          <img
            src={`flags/${selectedLanguage.abrev}.svg`}
            alt="Header Language"
            height="20"
            className="rounded"
          />
        </DropdownToggle>
        <DropdownMenu className="notify-item py-2">
          {languagesAvailable.map(lang => (
            <DropdownItem
              key={lang.languageId}
              onClick={() => changeLanguageAction(lang)}
              className={`notify-item ${selectedLang === lang.abrev ? 'active' : 'none'}`}>
              <img
                src={`flags/${lang.abrev}.svg`}
                alt={lang.name}
                className="me-2 rounded"
                height="18"
              />
              <span className="align-middle">{lang.name}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default LanguageDropdown
