import {css} from 'styled-components'

export const setSize = (size, defaultSize = 28) => {
  if (Array.isArray(size)) {
    return css`
      width: ${size?.[0]}px;
      height: ${size?.[1]}px;
    `
  }

  return css`
    width: ${size || defaultSize}px;
    height: ${size || defaultSize}px;
  `
}
