import React from 'react'

const index = ({data}) => {
  let textColor = '' // default text color
  // Determine text color based on the content of data
  if (data <= 50) {
    textColor = 'text-danger' // red text color
  } else if (data > 50) {
    textColor = 'text-success' // green text color
  }

  return (
    <div style={{textAlign: 'center'}}>
      <span className={`${textColor} text-center p-1 rounded border-primary`}>
        {data}
      </span>
    </div>
  )
}

export default index
