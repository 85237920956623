import {useForm, useFormState} from 'react-final-form'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {Card, CardBody} from 'reactstrap'

import BaseButton from '../../../../Components/Common/Buttons/BaseButton'
import Icon from '../../../../Components/Common/icon'
import '../../styles.scss'

const ProductCard = ({
  product,
  margin,
  lastAcquisition,
  baseValue,
  additionalPrice,
  getCurrencySymbol,
  isSaving,
  animate = true
}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/articles')
  }

  const {submit} = useForm()
  const {submitting} = useFormState()

  return (
    <Card className={`${animate && 'card-animate'} m-4 card-articles-details`}>
      <CardBody className="cardbody-articles-details d-flex">
        <div className="image-wrapper">
          <img
            src={
              product?.productPropertiesSection?.productImage[0]?.image?.url ||
              '/static/media/placeholder.7534730b7b518f895406.jpg'
            }
            width={84}
            height={104}
            className="product-image rounded"
            alt={product?.name || 'Product image'}
          />
        </div>
        <div className="box">
          <div className="flex-grow-1 upper-row">
            <span className="fs-14 heading_1">
              {product?.productPropertiesSection?.productTranslation[0]?.name}
            </span>
            <div>
              <span className="heading_6 labels-tags">Ref:</span>
              <span className="bodyText_7 ref-text mt-1">
                {product?.referenceCode}
              </span>
            </div>
          </div>

          <div className="flex-grow-1 d-flex lower-row">
            <div className="d-flex gap-3">
              <div>
                <Icon size={[40, 40]} icon="PriceIcon" />
              </div>
              <div className="d-flex flex-column">
                <span className="fs-14 labels-tags heading_5">
                  {t('PRICE')} ({t('WITHOUT')} vat):
                </span>
                <div>
                  <span className="bodyText_1 mt-1">{baseValue} €</span>
                  {additionalPrice && (
                    <>
                      <i className="mdi mdi-arrow-right-thin"></i>
                      <span className="bodyText_1 mt-1">
                        {additionalPrice.baseValue}{' '}
                        {getCurrencySymbol(additionalPrice.currencyId)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex gap-4 box-items">
              <div className="d-flex gap-3">
                <Icon size={[40, 40]} icon="GrossMargin" />
                <div className="d-flex flex-column">
                  <span className="fs-14 labels-tags heading_5">
                    Gross Margin:
                  </span>
                  <span className="bodyText_1 mt-1">
                    {product?.productPriceSection?.marginPercentage?.toFixed(
                      2
                    ) ||
                      margin ||
                      '-'}{' '}
                    %
                  </span>
                </div>
              </div>

              <div className="d-flex gap-3">
                <Icon size={[40, 40]} icon="AquisitionPrice" />
                <div className="d-flex flex-column">
                  <span className="fs-14 labels-tags heading_5">
                    Last Acquisition Price
                  </span>
                  <span className="bodyText_1 mt-1">
                    {product?.lastacquisitionprice ?? lastAcquisition} €
                  </span>
                </div>
              </div>

              <div className="d-flex gap-3">
                <Icon size={[40, 40]} icon="PCM" />
                <div className="d-flex flex-column">
                  <span className="fs-14 labels-tags heading_5 mt-1">
                    PCM PHC:
                  </span>
                  <span className="bodyText_1">{product?.pcm_phc ?? '-'}</span>
                </div>
              </div>

              <div className="buttons-wrap">
                <BaseButton
                  type="secondary"
                  height="small"
                  text="Back"
                  onClick={handleBackClick}
                />
                <BaseButton
                  height="small"
                  text="Save"
                  htmlType="submit"
                  onClick={submit}
                  disabled={submitting}
                  loading={isSaving}
                />
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default ProductCard
