import React from 'react'

import {Alert, UncontrolledAlert} from 'reactstrap'

import Icon from '../icon'

const Alerts = ({
  color,
  text,
  additional,
  borderless,
  heading,
  paragraph,
  icon
}) => {
  return (
    <>
      {borderless && (
        <Alert color={color} className="border-0 border-0">
          <span className="headless-title">{text}</span>
        </Alert>
      )}

      {additional && (
        <UncontrolledAlert color={color} className="alert-additional">
          <div className="alert-body">
            <div className="d-flex">
              <div className="flex-shrink-0 me-3">
                <Icon
                  style={{marginTop: '2px'}}
                  className="fs-16 align-middle"
                  icon={icon}
                  size={[16, 16]}
                />
              </div>
              <div className="flex-grow-1">
                <h5 className="alert-heading">{heading}</h5>
                <p className="mb-0 alert-subtitle ">{paragraph}</p>
              </div>
            </div>
          </div>
          <div className="alert-content">
            <p className="mb-0 text-white headless-title">{text}</p>
          </div>
        </UncontrolledAlert>
      )}
    </>
  )
}

export default Alerts
