import client from '../config/AxiosConfig'

export const GetInfoOrdersB2B = async () =>
  client.get('/orderB2B/GetInfoOrdersB2B')

export const GetOrdersB2B = async () =>
  client.get('/orderB2B/GetOrdersB2B?Page=1&ItemsPerPage=25')

export const GetOrderB2BDetails = async id =>
  client.get(`/orderB2B/GetOrderB2B/${id}`)

export const GetOrderPO = async id =>
  client.get(`/orderB2B/GetOrderB2B/${id}/InternalPO`)

export const GetOrderOS = async id =>
  client.get(`/orderB2B/GetOrderB2B/${id}/OrderSupplier`)

export const CreateOrderB2B = async data =>
  client.post('/orderB2B/CreateOrderB2B', data)

export const UpdateOrderB2B = async (id, data) =>
  client.put(`/orderB2B/UpdateOrderB2B/${id}`, data)

export const SearchProducts = async search =>
  client.get(`Products/GetProductsAndSkusByRef${search}`)

export const CreatInternalPO = async data =>
  client.post('OrderPO/CreateOrderPODraft', data)

export const GetOrderLocations = async (id = 0) =>
  client.get(`Contacts/GetOrdersLocation/${id}`)

export const AppendOrderFiles = async (id, payload) =>
  client.post(`OrderB2B/PostOrderB2BFiles/${id}`, payload)

export const DeleteOrderAttachment = async file =>
  client.delete(`OrderB2B/DeleteOrderB2BFile/${file}`)
