import React, {useEffect} from 'react'

import PropTypes from 'prop-types'
//redux
import {useDispatch, useSelector} from 'react-redux'
import {Navigate} from 'react-router-dom'
import {createSelector} from 'reselect'

import withRouter from '../../Components/Common/withRouter'
import {logoutUser} from '../../slices/thunks'

// const Logout = (props) => {
const Logout = () => {
  const dispatch = useDispatch()

  const logoutData = createSelector(
    state => state.Login,
    isUserLogout => isUserLogout.isUserLogout
  )
  // Inside your component
  const isUserLogout = useSelector(logoutData)

  useEffect(() => {
    dispatch(logoutUser())
  }, [dispatch])

  if (isUserLogout) {
    return <Navigate to="/login" />
  }

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object
}

export default withRouter(Logout)
