import {useState} from 'react'

import {Container} from 'reactstrap'

import withRouter from '../../../Components/Common/withRouter'
import AuthenticationHeader from '../AuthenticationHeader'
import ParticlesAuth from '../ParticlesAuth'
import LockScreen from './LockScreen'
import PasswordUpdateConfrimation from './PasswordUpdateConfirmation'
import ResetPassword from './ResetPassword'

const STEPS = {
  RESET_PASSWORD: 'ResetPassword',
  PASSWORD_UPDATE_CONFIRMATION: 'PasswordUpdateConfirmation',
  LOCK_SCREEN: 'LockScreen'
}

const ResetPasswordFlow = () => {
  const [currentStep, setCurrentStep] = useState(STEPS.RESET_PASSWORD)

  const handleContinueClick = () => {
    setCurrentStep(STEPS.LOCK_SCREEN)
  }

  const handleSubmitResetPassword = value => {
    console.log('Submitting passwords: ', value)
    setCurrentStep(STEPS.PASSWORD_UPDATE_CONFIRMATION)
  }

  return (
    <ParticlesAuth>
      <div className="auth-page-content mt-lg-5">
        <Container>
          <AuthenticationHeader />

          {currentStep === STEPS.RESET_PASSWORD && (
            <ResetPassword
              onSubmit={value => handleSubmitResetPassword(value)}
            />
          )}
          {currentStep === STEPS.PASSWORD_UPDATE_CONFIRMATION && (
            <PasswordUpdateConfrimation
              handleContinueClick={handleContinueClick}
            />
          )}
          {currentStep === STEPS.LOCK_SCREEN && <LockScreen />}
        </Container>
      </div>
    </ParticlesAuth>
  )
}

export default withRouter(ResetPasswordFlow)
