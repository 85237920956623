import { default as i18n } from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationPT from './locales/pt.json'
import translationEN from './locales/en.json'

const resources = {
  pt: {
    translation: translationPT
  },
  en: {
    translation: translationEN,
  },
}

const normalizeLanguageSetting = () => {
  try {
    const storedLanguage = localStorage.getItem('I18N_LANGUAGE')

    if (!storedLanguage) {
      localStorage.setItem('I18N_LANGUAGE', JSON.stringify({ abrev: 'en', id: 1 }))
      return { abrev: 'en', id: 1 }
    }

    let parsedLanguage
    try {
      parsedLanguage = JSON.parse(storedLanguage)
    } catch (e) {
      const defaultLanguage = { abrev: storedLanguage || 'en', id: 1 }
      localStorage.setItem('I18N_LANGUAGE', JSON.stringify(defaultLanguage))
      return defaultLanguage
    }

    if (!parsedLanguage || typeof parsedLanguage !== 'object' || !parsedLanguage.abrev) {
      const defaultLanguage = {
        abrev: typeof parsedLanguage === 'string' ? parsedLanguage : 'en',
        id: 1,
      }
      localStorage.setItem('I18N_LANGUAGE', JSON.stringify(defaultLanguage))
      return defaultLanguage
    }

    return parsedLanguage
  } catch (error) {
    return { abrev: 'en', id: 1 }
  }
}

const currentLanguage = normalizeLanguageSetting()

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: currentLanguage.abrev,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
