import React, {useState} from 'react'

import {useFormik} from 'formik'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap'
import {createSelector} from 'reselect'
import * as Yup from 'yup'

import withRouter from '../../Components/Common/withRouter'
import Logo from '../../assets/imagesNew/my-sky-pro.png'
import {loginUser, saveUserEmailForForgotPassword} from '../../slices/thunks'
import AuthenticationHeader from './AuthenticationHeader'
import ParticlesAuth from './ParticlesAuth'
import './styles.scss'

const Login = props => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const selectLayoutState = state => state
  const loginpageData = createSelector(selectLayoutState, state => ({
    user: state.Account.user,
    error: state.Login.error,
    loading: state.Login.loading,
    errorMsg: state.Login.errorMsg
  }))
  const {error, loading, errorMsg} = useSelector(loginpageData)
  const [passwordShow, setPasswordShow] = useState(false)
  const hasEmail = localStorage.getItem('user_email') ? true : false
  const [rememberMe, setRememberMe] = useState(hasEmail)

  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe)
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: hasEmail ? localStorage.getItem('user_email') : '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t('PLEASE_ENTER_YOUR_EMAIL')),
      password: Yup.string().required(t('PLEASE_ENTER_YOUR_PASSWORD'))
    }),
    onSubmit: values => {
      dispatch(loginUser(values, props.router.navigate, rememberMe))
    }
  })

  const handleForgotPasswordClick = () => {
    dispatch(saveUserEmailForForgotPassword(validation.values.email))
  }

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content mt-lg-5">
          <Container>
            <AuthenticationHeader />
            <Form
              onSubmit={e => {
                e.preventDefault()
                validation.handleSubmit()
                return false
              }}
              action="#">
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5} className="login-form-card">
                  <div className="text-center mt-sm-5 text-white login-mobile">
                    <div>
                      <Link to="/" className="d-inline-block auth-logo">
                        <img src={Logo} alt="" height="40" />
                      </Link>
                    </div>
                    <p className="mt-3 mb-0 fw-bold login-title">
                      {t('LOGIN_TITLE')}
                    </p>
                  </div>
                  <Card className="mt-2">
                    <CardBody className="login-card-body">
                      <div className="text-center mt-2">
                        <h5 className="text-primary">{t('HELLO_AGAIN')}</h5>
                        <p className="text-muted">
                          {t('PLEASE_LOGIN_TO_YOUR_ACCOUNT')}
                        </p>
                      </div>
                      {errorMsg && (
                        <Alert color="danger">{t('LOGIN_ERROR_MESSAGE')}</Alert>
                      )}
                      <div className="p-2 mt-4">
                        <div className="mb-3">
                          <Label
                            htmlFor="userName"
                            className="form-label"
                            style={{fontSize: 14}}>
                            {t('USERNAME')}
                            <span className=" text-danger"> *</span>
                          </Label>
                          <Input
                            autoFocus={hasEmail ? false : true}
                            name="email"
                            className="form-control"
                            placeholder={t('ENTER_USERNAME')}
                            type="text"
                            onChange={validation.handleChange}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                            style={{fontSize: 14}}>
                            {t('PASSWORD')}
                            <span className=" text-danger"> *</span>
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              autoFocus={hasEmail ? true : false}
                              name="password"
                              value={validation.values.password || ''}
                              type={passwordShow ? 'text' : 'password'}
                              className="form-control pe-5"
                              placeholder={t('ENTER_PASSWORD')}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            {validation.touched.password &&
                            validation.errors.password ? null : (
                              <button
                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                                type="button"
                                id="password-addon"
                                onClick={() => setPasswordShow(!passwordShow)}>
                                <i className="ri-eye-fill align-middle"></i>
                              </button>
                            )}
                          </div>
                          <div className="lh-13 fs-11 fw-475 text-muted">
                            {t('LOGIN_TERMS_OF_USE_STRING')}
                            {'    '}
                            <Link to="/forgot-password">
                              <span
                                className="login-terms-tag-desktop"
                                style={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  color: '#64A0E3',
                                  textDecoration: 'underline'
                                }}>
                                {t('TERMS_OF_USE')}
                              </span>

                              <p
                                className="login-terms-tag-mobile"
                                style={{
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  color: '#64A0E3',
                                  textDecoration: 'underline'
                                }}>
                                {t('TERMS_OF_USE')}
                              </p>
                            </Link>
                          </div>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="info"
                            disabled={error ? null : loading ? true : false}
                            className="btn w-100 text-white"
                            type="submit">
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {` ${t('LOADING')} `}...
                              </Spinner>
                            ) : null}
                            {t('SIGN_IN')}
                          </Button>
                        </div>

                        {/* SOCIAL MEDIA SIGN IN */}
                        {/* <div className="mt-4 text-center">
                          <div className="signin-other-title">
                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                          </div>
                          <div>
                            <Link
                              to="#"
                              className="btn btn-primary btn-icon me-1"
                              onClick={(e) => {
                                e.preventDefault();
                                socialResponse("facebook");
                              }}
                            >
                              <i className="ri-facebook-fill fs-16" />
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-danger btn-icon me-1"
                              onClick={(e) => {
                                e.preventDefault();
                                socialResponse("google");
                              }}
                            >
                              <i className="ri-google-fill fs-16" />
                            </Link>
                            <Button color="dark" className="btn-icon">
                              <i className="ri-github-fill fs-16"></i>
                            </Button>{" "}
                            <Button color="info" className="btn-icon">
                              <i className="ri-twitter-fill fs-16"></i>
                            </Button>
                          </div>
                        </div> */}
                      </div>
                    </CardBody>
                  </Card>
                  <div className=" d-flex justify-content-around">
                    <div
                      className="form-check"
                      style={{display: 'flex', gap: 11, alignItems: 'center'}}>
                      <Input
                        className={
                          'form-check-input ' +
                          (rememberMe && 'bg-info border-info')
                        }
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleCheckboxChange}
                        id="auth-remember-check"
                        style={{marginTop: 0}}
                      />
                      <Label
                        className="form-check-label fw-normal remember-me-label"
                        htmlFor="auth-remember-check">
                        {t('REMEMBER_ME')}
                      </Label>
                    </div>
                    <Link
                      to="/forgot-password"
                      onClick={handleForgotPasswordClick}>
                      <span
                        style={{
                          color: '#64A0E3',
                          textDecoration: 'underline'
                        }}>
                        {t('FORGOT_PASSWORD')}
                      </span>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)
