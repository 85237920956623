import React from 'react'

const index = () => {
  return (
    <div className="page-content">
      <h1 className="display_2">Display Headings</h1>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Display 1</p>
          <p>Weight: 800 · Size: 40 px · Bold · Line Height: 48 px · 2.5 rem</p>
        </div>
        <p className="display_1"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Display 2</p>
          <p>
            Weight: 500 · Size: 30 px · Medium · Line Height: 36 px · 1.875 rem
          </p>
        </div>
        <p className="display_2"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Display 3</p>
          <p>
            Weight: 700 · Size: 18 px · Bold · Line Height: 21.6 px · 1.125 rem
          </p>
        </div>
        <p className="display_3"> Welcome to mySkypro Portal</p>
      </div>
      <h1 className="display_2">Page title</h1>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Page Title 1 </p>
          <p>
            Weight: 700 · Size: 15 px · Bold · Line Height: 18 px · 0.938 rem
          </p>
        </div>
        <p className="page_title"> Welcome to mySkypro Portal</p>
      </div>
      <h1 className="display_2">Headings</h1>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 1</p>
          <p>Weight: 500 · Size: 16 px · Medium · Line Height: 19 px · 1 rem</p>
        </div>
        <p className="heading_1"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 2</p>
          <p>
            Weight: 700 · Size: 14 px · Bold · Line Height: 17 px · 0.875 rem
          </p>
        </div>
        <p className="heading_2"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 3</p>
          <p>
            Weight: 500 · Size: 14 px · Medium · Line Height: 16.8 px · 0.875
            rem
          </p>
        </div>
        <p className="heading_3"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 4</p>
          <p>
            Weight: 700 · Size: 13 px · Bold · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="heading_4"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 5</p>
          <p>
            Weight: 500 · Size: 13 px · Medium · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="heading_5"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 6</p>
          <p>
            Weight: 400 · Size: 13 px · Book · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="heading_6"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Heading 7</p>
          <p>
            Weight: 500 · Size: 12 px · Medium · Line Height: 16 px · 0.75 rem
          </p>
        </div>
        <p className="heading_7"> Welcome to mySkypro Portal</p>
      </div>
      <h1 className="display_2">Body Text</h1>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 1 </p>
          <p>
            Weight: 500 · Size: 17 px · Medium · Line Height: 20 px · 1.25t rem
          </p>
        </div>
        <p className="bodyText_1"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 2 </p>
          <p>Weight: 500 · Size: 16 px · Medium · Line Height: 19 px · 1 rem</p>
        </div>
        <p className="bodyText_2"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 3 </p>
          <p>
            Weight: 400 · Size: 15 px · Book · Line Height: 18 px · 0.938 rem
          </p>
        </div>
        <p className="bodyText_3"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 4 </p>
          <p>
            Weight: 700 · Size: 14 px · Bold · Line Height: 17 px · 0.875 rem
          </p>
        </div>
        <p className="bodyText_4"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 5 </p>
          <p>
            Weight: 500 · Size: 14 px · Medium · Line Height: 17 px · 0.875 rem
          </p>
        </div>
        <p className="bodyText_5"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 6 </p>
          <p>
            Weight: 400 · Size: 14 px · Book · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="bodyText_6"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 7 </p>
          <p>
            Weight: 700 · Size: 13 px · Bold · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="bodyText_7"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 8 </p>
          <p>
            Weight: 500 · Size: 13 px · Medium · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="bodyText_8"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 9 </p>
          <p>
            Weight: 400 · Size: 13 px · Book · Line Height: 16 px · 0.813 rem
          </p>
        </div>
        <p className="bodyText_9"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 10 </p>
          <p>
            Weight: 400 · Size: 12 px · Book · Line Height: 14.4 px · 0.75 rem
          </p>
        </div>
        <p className="bodyText_10"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 11 </p>
          <p>
            Weight: 400 · Size: 11 px · Book · Line Height: 13.2 px · 0.688 rem
          </p>
        </div>
        <p className="bodyText_11"> Welcome to mySkypro Portal</p>
      </div>
      <div
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        <div>
          <p>Body Text 12 </p>
          <p>
            Weight: 400 · Size: 9,5 px · Book · Line Height: 11.4 px · 0.594 rem
          </p>
        </div>
        <p className="bodyText_12"> Welcome to mySkypro Portal</p>
      </div>
    </div>
  )
}

export default index
