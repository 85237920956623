import MultipleSelectInput from '../Inputs/MultipleSelectInput/MultipleSelectInput'

const MultipleSelectAdaptor = ({input, ...remainingProps}) => (
  <MultipleSelectInput
    {...input}
    {...remainingProps}
    onChange={value => input.onChange(value)}
  />
)

export default MultipleSelectAdaptor
