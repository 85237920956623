import React, {useEffect, useRef, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Input} from 'reactstrap'
import SimpleBar from 'simplebar-react'

import Placeholder from '../../assets/imagesNew/placeholder.jpg'
import {GetOrdersB2B} from '../../infra/requests/OrdersB2BRequests'
import {GetB2BProducts} from '../../infra/requests/ProductsRequests'
import {
  ConstructQuery,
  ConstructSort
} from '../../shared/logic/queries/EndpointQueries'
import Loader from './Loader'
import withRouter from './withRouter'

const HAS_MORE_ITEMS_LIMIT = 5

const SearchOption = ({router}) => {
  const {t} = useTranslation()
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [products, setProducts] = useState([])
  const [hasMoreProducts, setHasMoreProducts] = useState(false)
  const inputRef = useRef(null)

  const onChangeData = value => {
    setValue(value)
  }
  const hasData = orders.length > 0 || products.length > 0

  useEffect(() => {
    var searchOptions = document.getElementById('search-close-options')
    var dropdown = document.getElementById('search-dropdown')
    var searchInput = document.getElementById('search-options')

    if (hasData) {
      dropdown.classList.add('show')
    } else {
      dropdown.classList.remove('show')
    }

    searchInput.addEventListener('focus', function () {
      var inputLength = searchInput.value.length
      if (inputLength > 2 && hasData) {
        dropdown.classList.add('show')
        searchOptions.classList.remove('d-none')
      } else {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })

    searchInput.addEventListener('keyup', function () {
      var inputLength = searchInput.value.length
      if (inputLength > 2 && hasData) {
        dropdown.classList.add('show')
        searchOptions.classList.remove('d-none')
      } else {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })

    searchOptions.addEventListener('click', function () {
      searchInput.value = ''
      dropdown.classList.remove('show')
      searchOptions.classList.add('d-none')
    })

    document.body.addEventListener('click', function (e) {
      if (e.target.getAttribute('id') !== 'search-options') {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })
  }, [hasData])

  useEffect(() => {
    const handleOrdersRequest = async () => {
      try {
        setLoading(true)
        const {success, data} = await GetOrdersB2B(
          1,
          5,
          ConstructQuery({search: value}),
          ConstructSort()
        )

        if (success) {
          if (data.items.length > 0) {
            setOrders(data.items)
            setHasMoreOrders(data.totalItems > 5 ? true : false)
          } else {
            setOrders([])
            setHasMoreOrders(false)
          }
        }

        setLoading(false)
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }

    const handleProductsRequest = async () => {
      try {
        setLoading(true)
        const {success, data} = await GetB2BProducts(
          1,
          5,
          ConstructQuery({search: value}),
          ConstructSort()
        )

        if (success) {
          if (data.items.length > 0) {
            setProducts(data.items)
            setHasMoreProducts(
              data.totalItems > HAS_MORE_ITEMS_LIMIT ? true : false
            )
          } else {
            setProducts([])
            setHasMoreProducts(false)
          }
        }

        setLoading(false)
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }

    if (value.length >= 3) {
      handleOrdersRequest()
      handleProductsRequest()
    }
  }, [value])

  const handleKeyPress = event => {
    var dropdown = document.getElementById('search-dropdown')

    if (event.key === 'Enter') {
      event.preventDefault()

      if (orders.length === 1 && products.length === 0) {
        router.navigate(`/iframe?url=orders/b2b/${orders[0].orderB2bid}`)
        setValue('')
        inputRef.current.blur()
        dropdown.classList.remove('show')
      }

      if (products.length === 1 && orders.length === 0) {
        router.navigate(`/iframe?url=products/${products[0].productId}`)
        setValue('')
        inputRef.current.blur()
        dropdown.classList.remove('show')
      }
    }
  }

  return (
    <React.Fragment>
      <form className="app-search d-none d-md-block">
        <div className="position-relative">
          <Input
            autoComplete="off"
            type="text"
            className="form-control"
            placeholder={`${t('SEARCH')}...`}
            id="search-options"
            value={value}
            onChange={e => {
              onChangeData(e.target.value)
            }}
            onKeyPress={e => handleKeyPress(e)}
            innerRef={inputRef}
          />
          <span className="mdi mdi-magnify search-widget-icon"></span>
          <span
            className={
              value.length > 0
                ? 'mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none'
                : ' search-widget-icon search-widget-icon-close d-none'
            }
            id="search-close-options"
          />
        </div>
        <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
          <SimpleBar style={{maxHeight: '320px'}}>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  height: 320
                }}>
                <Loader />
              </div>
            ) : (
              <>
                {orders.length > 0 && (
                  <>
                    <div className="dropdown-header mt-2">
                      <h6 className="text-overflow text-muted mb-2 text-uppercase">
                        {t('ORDERS')}
                      </h6>
                    </div>
                    <div className="notification-list">
                      {orders.map((order, index) => (
                        <div
                          key={index}
                          className="dropdown-item notify-item py-2"
                          style={{whiteSpace: 'normal', cursor: 'pointer'}}
                          onClick={() => {
                            setValue('')
                            router.navigate(
                              `/iframe?url=orders/b2b/${order.orderB2bid}`
                            )
                          }}>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h6 className="m-0">{order.number}</h6>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {hasMoreOrders && (
                      <div className="pt-3 pb-1 text-center">
                        <div
                          onClick={() => {
                            setValue('')
                            router.navigate(
                              `/iframe?url=orders?search=${value}`
                            )
                          }}
                          className="btn btn-primary btn-sm">
                          {t('VIEW_ALL_ORDERS')}
                          <i className="ri-arrow-right-line ms-1"></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {products.length > 0 && (
                  <>
                    <div className="dropdown-header mt-2">
                      <h6 className="text-overflow text-muted mb-2 text-uppercase">
                        {t('PRODUCTS')}
                      </h6>
                    </div>
                    <div
                      className="notification-list"
                      style={{overflow: 'hidden'}}>
                      {products.map((product, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setValue('')
                            router.navigate(
                              `/iframe?url=products/${product.productId}`
                            )
                          }}
                          className="dropdown-item notify-item py-2"
                          style={{whiteSpace: 'normal', cursor: 'pointer'}}>
                          <div className="d-flex">
                            <div style={{width: 45, height: 35}}>
                              <img
                                src={
                                  product.productImage.length > 0
                                    ? product.productImage[0].image.url
                                    : Placeholder
                                }
                                className="me-3 rounded-circle avatar-xs"
                                alt="product-image"
                              />
                            </div>
                            <div className="flex-grow-1">
                              <h6 className="m-0">
                                {product.productTranslation[0].name}
                              </h6>
                              <span className="fs-11 mb-0 text-muted">
                                {product.referenceCode}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {hasMoreProducts && (
                      <div className="pt-3 pb-1 text-center">
                        <div
                          onClick={() => {
                            setValue('')
                            router.navigate(
                              `/iframe?url=products?search=${value}`
                            )
                          }}
                          className="btn btn-primary btn-sm">
                          {t('VIEW_ALL_PRODUCTS')}
                          <i className="ri-arrow-right-line ms-1"></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div style={{marginBottom: 6}} />
              </>
            )}
          </SimpleBar>
        </div>
      </form>
    </React.Fragment>
  )
}

export default withRouter(SearchOption)
