import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import {Col, Row} from 'reactstrap'

import Logo from '../../assets/imagesNew/my-sky-pro.png'
import './styles.scss'

const AuthenticationHeader = () => {
  const {t} = useTranslation()

  return (
    <div className="mb-lg-5 mb-md-5 authentication-header">
      <Row>
        <Col lg={12}>
          <div className="text-center mt-sm-5 mb-4 text-white login-desktop">
            <div>
              <Link to="/" className="d-inline-block auth-logo">
                <img src={Logo} alt="" height="50" />
              </Link>
            </div>
            <p className="mt-3 mb-0 fw-bold login-title">{t('LOGIN_TITLE')}</p>
          </div>
          <div className="text-center text-white login-mobile">
            <div>
              <Link to="/" className="d-inline-block auth-logo">
                <img src={Logo} alt="" height="40" />
              </Link>
            </div>
            <p className="mt-3 mb-0 fw-bold login-title">{t('LOGIN_TITLE')}</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AuthenticationHeader
