import {mockApiResponse} from './mockData'

export const tableColumns = {
  size: 'normal',
  scroll: {
    scrollable: true,
    scrollHeight: 'calc(100vh - 350px)'
  },
  columns: [
    {
      header: '#',
      field: 'transferId',
      type: 'text'
    },
    {
      header: 'Client',
      field: 'b2bClientName',
      type: 'text',

      columnFilter: 'multiple',
      filterName: 'client',
      filters: mockApiResponse.b2bClients.map(client => ({
        label: client.name,
        value: client.name
      }))
    },
    {
      header: 'Status',
      field: 'conclusionLabel',
      type: 'text',

      columnFilter: 'multiple',
      filterName: 'status',
      filters: mockApiResponse.conclusionStatuses.map(status => ({
        label: status.conclusionLabel,
        value: status.conclusionLabel
      }))
    },
    {
      header: 'Origin Warehouse',
      field: 'originWarehouseName',
      type: 'text',

      columnFilter: 'multiple',
      filterName: 'originWarehouse',
      filters: mockApiResponse.originWarehouses.map(warehouse => ({
        label: warehouse.name,
        value: warehouse.name
      }))
    },
    {
      header: 'Destiny Warehouse',
      field: 'destinyWarehouseName',
      type: 'text',

      columnFilter: 'multiple',
      filterName: 'destinyWarehouse',
      filters: mockApiResponse.destinyWarehouses.map(warehouse => ({
        label: warehouse.name,
        value: warehouse.name
      }))
    },
    {
      header: 'Total units',
      field: 'totalUnits',
      type: 'number',
      align: 'right'
    },
    {
      header: 'Date',
      field: 'dateCreated',
      type: 'date-time',

      columnFilter: 'date',
      filterName: 'date'
    },
    {
      header: 'Created by',
      field: 'createdByUser',
      type: 'text',

      columnFilter: 'multiple',
      filterName: 'createdBy',
      filters: mockApiResponse.users.map(user => ({
        label: user.fullName,
        value: user.fullName
      }))
    }
  ],
  editrow: {
    hasEdit: false
  }
}
