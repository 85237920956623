import React from 'react'

import {Pagination, PaginationItem, PaginationLink} from 'reactstrap'

import SelectInput from '../Inputs/SelectInput/SelectInput'
import Icon from '../icon'

const PaginationComponent = ({
  currentPage,
  totalPages,
  onPageChange,
  rowsPerPage,
  handleRowsPerPageChange,
  totalItems,
  paginationOptions,
  dataType
}) => {
  const maxVisiblePages = 3
  const renderPaginationItems = () => {
    const items = []
    const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem
          style={{borderRadius: 0}}
          key={i}
          active={currentPage === i}>
          <PaginationLink
            style={{borderRadius: 0}}
            onClick={() => onPageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>
      )
    }
    return items
  }

  return (
    <div className="pagination-controls align-items-center d-flex justify-content-between pagination-component-wrapper">
      <div className="d-flex mt-1 align-items-center">
        <div className="d-flex align-items-center justify-content-center">
          <div className="heading_5" style={{marginRight: '7px'}}>
            Show
          </div>
          <SelectInput
            small
            openTop
            value={currentPage}
            style={{width: 'auto'}}
            placeholder={rowsPerPage}
            onChange={value => handleRowsPerPageChange(value)}
            options={
              paginationOptions
                ? paginationOptions
                : [
                    {label: '12', value: 12},
                    {label: '24', value: 24},
                    {label: '48', value: 48}
                  ]
            }
          />

          <div className="heading_5" style={{marginLeft: '7px'}}>
            entries
          </div>
        </div>
        <div style={{marginLeft: 25}} className="d-flex align-items-center">
          <div className="heading_5">
            {`Showing ${
              currentPage === 1 ? 1 : (currentPage - 1) * rowsPerPage + 1
            } to ${Math.min(
              currentPage * rowsPerPage
            )} of ${totalItems} ${dataType}`}
          </div>
        </div>
      </div>
      {/* Pagination component */}
      <div className="d-flex align-content-center mt-3">
        <Pagination
          id="pagination"
          className="d-flex align-items-center relative">
          <PaginationItem disabled={currentPage === 1}>
            <PaginationLink
              onClick={() => onPageChange(currentPage - 1)}
              style={{
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                color: currentPage === 1 ? '#444F5999' : '#64A0E3',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 13
              }}>
              <Icon
                color={currentPage === 1 ? '#444F5999' : '#64A0E3'}
                size={[10, 10]}
                icon="rightArrow"
                style={{transform: 'rotate(180deg)', marginRight: 12}}
              />
              <div>Prev</div>
            </PaginationLink>
          </PaginationItem>
          {renderPaginationItems()}
          <PaginationItem disabled={currentPage === totalPages}>
            <PaginationLink
              onClick={() => onPageChange(currentPage + 1)}
              style={{
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                color: currentPage === totalPages ? '#444F5999' : '#64A0E3',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingLeft: 13
              }}>
              <div>Next</div>
              <Icon
                color={currentPage === totalPages ? '#444F5999' : '#64A0E3'}
                size={[10, 10]}
                icon="leftArrow"
                style={{transform: 'rotate(180deg)', marginLeft: 12}}
              />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    </div>
  )
}

export default PaginationComponent
