const alertsArray = [
  {
    color: 'primary',
    text: 'Remember: Primary alerts convey important information.',
    additional: false,
    borderless: true,
    heading: 'Remember',
    paragraph: 'Sample paragraph for primary alert.'
  },
  {
    color: 'secondary',
    text: 'Hey there! Secondary alerts provide additional context.',
    additional: false,
    borderless: true,
    heading: 'Hey there!',
    paragraph: 'Sample paragraph for secondary alert.'
  },
  {
    color: 'success',
    text: 'Great job! Success alerts indicate successful actions or outcomes.',
    additional: false,
    borderless: true,
    heading: 'Great job!',
    paragraph: 'Sample paragraph for success alert.'
  },
  {
    color: 'danger',
    text: 'Caution! Danger alerts warn about potential risks or errors.',
    additional: false,
    borderless: true,
    heading: 'Caution!',
    paragraph: 'Sample paragraph for danger alert.'
  },
  {
    color: 'warning',
    text: 'Heads up! Warning alerts highlight important notices.',
    additional: false,
    borderless: true,
    heading: 'Well done !',
    paragraph: 'Sample paragraph for warning alert.'
  },
  {
    color: 'info',
    icon: 'PrimaryAlertIcon',
    text: 'Did you know? Info alerts offer interesting or helpful facts.',
    additional: false,
    borderless: true,
    heading: 'Well done !',
    paragraph: 'Sample paragraph for info alert.'
  },
  {
    color: 'success',
    icon: 'SuccessAlertIcon',
    text: 'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.',
    additional: true,
    borderless: false,
    heading: 'Yey ! Everything worked!',
    paragraph: "This alert needs your attention, but it's not super important."
  },
  {
    color: 'danger',
    icon: 'DangerAlertIcon',
    text: 'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.',
    additional: true,
    borderless: false,
    heading: 'Something is very wrong!',
    paragraph: 'Change a few things up and try submitting again.'
  },
  {
    color: 'warning',
    icon: 'WarningAlertIcon',
    text: 'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.',
    additional: true,
    borderless: false,
    heading: 'Well done !',
    paragraph: "Better check yourself, you're not looking too good."
  },
  {
    color: 'info',
    icon: 'PrimaryAlertIcon',
    text: 'Whenever you need to, be sure to use margin utilities to keep things nice and tidy.',
    additional: true,
    borderless: false,
    heading: 'Well done !',
    paragraph: 'Aww yeah, you successfully read this important alert message.'
  }
]

export default alertsArray
