import {useTranslation} from 'react-i18next'
import {Card, CardBody, Col, Row} from 'reactstrap'

import Icon from '../../../Components/Common/icon'

const PasswordUpdateConfrimation = ({handleContinueClick}) => {
  const {t} = useTranslation()

  return (
    <Row className="justify-content-center">
      <Col md={8} lg={6} xl={5} className="login-form-card">
        <Card className="mt-2">
          <CardBody className="login-card-body">
            <div className="text-center mt-5 mb-5 d-flex flex-column align-items-center">
              <div className="mb-4">
                <Icon icon="Lock" fill="green" size={[66, 87]} />
              </div>
              <h5 className="text-success">{t('PASSWORD_UPDATED')}</h5>
              <p className="text-muted">{t('CONTINUE_TO_LOGIN')}</p>
            </div>
            <div className="text-center mt-5">
              <button
                className="btn btn-info w-100"
                type="button"
                onClick={handleContinueClick}>
                {t('CONTINUE')}
              </button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default PasswordUpdateConfrimation
