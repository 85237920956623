import Breadcrumb from '../../Components/Common/Breadcrumb'
import TransfersPage from './components/TransfersPage'
import './styles.scss'

const breadcrumbData = {
  pageTitle: 'Tranfers',
  items: [{title: 'Orders', url: '/transfers'}]
}

const Index = () => {
  return (
    <div className="page-content">
      <Breadcrumb breadcrumbData={breadcrumbData} />
      <div className="transfers-content-wrapper">
        <TransfersPage />
      </div>
    </div>
  )
}

export default Index
