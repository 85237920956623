import React from 'react'

import Icon from '../../Components/Common/icon'

const IconsPage = () => {
  const iconNames = [
    'orderIcon',
    'logisticsIcon',
    'productsIcon',
    'phoneIcon',
    'OrdercreatedIcon',
    'headsetsIcon',
    'filtersIcon',
    'DangerAlertIcon',
    'WarningAlertIcon',
    'PrimaryAlertIcon',
    'SuccessAlertIcon',
    'emailIcon',
    'searchIcon',
    'downArrowIcon',
    'closeIcon',
    'DotIcon',
    'columnFilterIcon',
    'linesIcon',
    'squareIcon',
    'doubleCheckLine',
    'plusLine',
    'timeBack',
    'ruller',
    'treeDots',
    'trash',
    'pdf',
    'xls',
    'xIcon',
    'pencil',
    'check',
    'battery1',
    'battery2',
    'battery3',
    'battery4',
    'battery5',
    'battery6',
    'envelope',
    'bell',
    'burguerSmall',
    'filters',
    'arrow',
    'leftArrow',
    'rightArrow',
    'PriceIcon',
    'GrossMargin',
    'AquisitionPrice',
    'PCM',
    'SendEmail',
    'EmailSent',
    'Lock',
    'Sort',
    'historyIcon'
  ]

  return (
    <div className="container p-4" style={{marginBlockStart: 100}}>
      <h1 className="h2 mb-4">Icons</h1>
      <div className="row">
        {iconNames.map(iconName => (
          <div key={iconName} className="col-3 mb-4">
            <div className="card p-3 text-center bg-secondary-subtle">
              <span className="text-muted mb-2">{iconName}</span>
              <div className="d-flex justify-content-center">
                <Icon icon={iconName} size={[40, 40]} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default IconsPage
