import React from 'react'

const Badge = ({type, color}) => {
  const handleType = () => {
    switch (type) {
      case 'primary':
        return 'base-badge-primary'
      case 'secondary':
        return 'base-badge-secondary'
      case 'soft':
        return 'base-badge-soft'
      default:
        return 'base-badge-primary'
    }
  }

  const handleColor = () => {
    switch (color) {
      case 'new':
        return 'new'
      case 'manufacturing':
        return 'manufacturing'
      case 'ready-to-process':
        return 'ready-to-process'
      case 'ready-to-ship':
        return 'ready-to-ship'
      case 'ready-to-pick-up':
        return 'ready-to-pick-up'
      case 'shiped':
        return 'shiped'
      case 'delivered':
        return 'delivered'
      case 'open-order':
        return 'open-order'
      case 'closed':
        return 'closed'
      case 'canceled':
        return 'canceled'
      case 'waiting':
        return 'waiting'
      default:
        return 'new'
    }
  }

  const handleText = () => {
    switch (color) {
      case 'new':
        return 'New'
      case 'manufacturing':
        return 'Manufacturing'
      case 'ready-to-process':
        return 'Ready to Process'
      case 'ready-to-ship':
        return 'Ready to Ship'
      case 'ready-to-pick-up':
        return 'Ready to Pick Up'
      case 'shiped':
        return 'Shiped'
      case 'delivered':
        return 'Delivered'
      case 'open-order':
        return 'Open Order'
      case 'closed':
        return 'Closed'
      case 'canceled':
        return 'Canceled'
      case 'waiting':
        return 'Waiting'
      default:
        return 'new'
    }
  }

  return (
    <div>
      <div
        style={{whiteSpace: 'nowrap'}}
        className={`base-badge-default bodyText_11 ${handleType()} ${handleColor()}`}>
        {handleText()}
      </div>
    </div>
  )
}

export default Badge
