import React, {useState} from 'react'

import {Dropdown, DropdownToggle} from 'reactstrap'

import withRouter from './withRouter'

const NotificationDropdown = ({router}) => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false)
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown)
  }

  return (
    <React.Fragment>
      <a href="/iframe?url=messaging">
        <Dropdown
          onClick={() => router.navigate('/iframe?url=messaging')}
          isOpen={isNotificationDropdown}
          toggle={toggleNotificationDropdown}
          className="topbar-head-dropdown ms-1 header-item">
          <DropdownToggle
            type="button"
            tag="button"
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
            <i className="bx bx-bell fs-22"></i>
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger" />
          </DropdownToggle>
        </Dropdown>
      </a>
    </React.Fragment>
  )
}

export default withRouter(NotificationDropdown)
