import React, {useEffect} from 'react'

import ReactDOM from 'react-dom'
import {useTranslation} from 'react-i18next'
import {Spinner} from 'reactstrap'

import {GetSKUConsumptionDetails} from '../../../../../infra/requests/ForecastRequests'
import './CloudChartTooltip.scss'

const CloudChartTooltip = ({
  client,
  orderDate,
  date,
  totalCost,
  totalSales,
  position,
  b2bclientId,
  tooltipDetails,
  setTooltipDetails,
  isLoadingTooltipDetails,
  setIsLoadingTooltipDetails,
  dataKey
}) => {
  const isLoading = isLoadingTooltipDetails?.[dataKey]
  const {t} = useTranslation()

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoadingTooltipDetails(prev => ({...prev, [dataKey]: true}))
      if (!tooltipDetails[dataKey]) {
        const body = {
          B2bclientId: b2bclientId,
          OrderDate: date
        }
        try {
          const {success, data} = await GetSKUConsumptionDetails(body)
          if (success) {
            setTooltipDetails(prev => ({
              ...prev,
              [dataKey]: data
            }))
          }
        } catch (error) {
          console.error(error)
        }
      }
      setIsLoadingTooltipDetails(prev => ({...prev, [dataKey]: false}))
    }

    fetchDetails()
  }, [])

  const details = tooltipDetails[dataKey]

  const itemList = details?.items
  const sortedItems = itemList?.sort((a, b) => b.orderQty - a.orderQty)

  const displayItems = sortedItems?.slice(0, 4)
  const remainingItemsCount = details?.items.slice(5)?.length

  const toTitleCase = str => {
    return str
      .toLowerCase()
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(' ')
  }

  return ReactDOM.createPortal(
    <div
      id="chartjs-tooltip"
      style={{
        opacity: 1,
        position: 'absolute',
        left: `${position.left}px`,
        top: `${position.top}px`,
        pointerEvents: 'none',
        fontFamily: 'Campton, sans-serif'
      }}>
      <div className="tooltip-header">
        <div className="client-info">
          <h3>{client}</h3>
          <p>{`${t('ORDER_DATE')}: ${orderDate}`}</p>
        </div>
        <div className="order-info text-end">
          <p>{`${t('TOTAL_COST')}: ${totalCost}`}</p>
          <p>{`${t('TOTAL_SALES')}: ${totalSales}`}</p>
        </div>
      </div>
      {isLoading ? (
        <div
          className="w-100 d-flex justify-content-center spinner-container"
          style={{
            height: 151
          }}>
          <Spinner className="m-auto my-5 py-3 table-chart-spinner">
            Loading...
          </Spinner>
        </div>
      ) : details ? (
        <div className="py-2 px-3">
          <div className="tooltip-body">
            {displayItems.map((item, index) => (
              <div
                className="border-bottom d-flex justify-content-between"
                key={index}>
                <div style={{width: '50%'}}>
                  <p className="text-nowrap overflow-hidden text-truncate w-100">
                    {toTitleCase(item.productDescription[0].name)}
                  </p>
                </div>
                <div className="p-0 d-flex" style={{width: '50%'}}>
                  <div className="d-flex">
                    <p
                      className="fw-bold"
                      style={{
                        paddingInlineStart: 40,
                        width: 90
                      }}>
                      {`${t('SIZE')}: ${item.sizeNameEU}`}
                    </p>
                    <p className="fw-bold ps-1 text-center">
                      {`${t('QTY')}: ${item.orderQty}`}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="tooltip-footer">
            <p
              style={{
                fontSize: 12,
                fontWeight: 700,
                lineHeight: '15px'
              }}>
              +{remainingItemsCount} SKUs
            </p>
          </div>
        </div>
      ) : (
        <div className="p-3 text-center">{t('NO_DATA')}</div>
      )}
    </div>,
    document.body
  )
}

export default CloudChartTooltip
