import {useEffect, useState} from 'react'

import Table from '../../../Components/Common/Table'
import Filters from './Filters'
import {tableColumns} from './columnsConfig'
import {mockApiResponse} from './mockData'

const TransfersPage = () => {
  const [filters, setFilters] = useState({
    search: '',
    b2bClientId: [],
    purchaseOrder: '',
    b2bOrderNumber: ''
  })

  const [columnFilters, setColumnFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tableData, setTableData] = useState(mockApiResponse.transfers.items)

  const filterOptions = {
    b2bClients: mockApiResponse.b2bClients
  }

  const applyFilters = () => {
    let filteredData = [...mockApiResponse.transfers.items]

    if (filters.search) {
      const searchLower = filters.search.toLowerCase()
      filteredData = filteredData.filter(item => {
        return (
          item.transferId.toString().toLowerCase().includes(searchLower) ||
          (item.reference &&
            item.reference.toLowerCase().includes(searchLower)) ||
          (item.barcode && item.barcode.toLowerCase().includes(searchLower))
        )
      })
    }

    if (filters.b2bClientId) {
      const selectedClient = mockApiResponse.b2bClients.find(
        client => client.b2bClientId === filters.b2bClientId
      )
      if (selectedClient) {
        filteredData = filteredData.filter(
          item => item.b2bClientName === selectedClient.name
        )
      }
    }

    if (filters.purchaseOrder) {
      const poLower = filters.purchaseOrder.toLowerCase()
      filteredData = filteredData.filter(
        item =>
          item.purchaseOrder &&
          item.purchaseOrder.toLowerCase().includes(poLower)
      )
    }

    if (filters.b2bOrderNumber) {
      const orderLower = filters.b2bOrderNumber.toLowerCase()
      filteredData = filteredData.filter(
        item =>
          item.b2bOrderNumber &&
          item.b2bOrderNumber.toLowerCase().includes(orderLower)
      )
    }

    Object.entries(columnFilters).forEach(([key, value]) => {
      if (value) {
        const fieldMapping = {
          client: 'b2bClientName',
          status: 'conclusionLabel',
          originWarehouse: 'originWarehouseName',
          destinyWarehouse: 'destinyWarehouseName',
          createdBy: 'createdByUser'
        }

        const field = fieldMapping[key] || key

        filteredData = filteredData.filter(item => {
          if (key === 'date' && Array.isArray(value) && value.length === 2) {
            const itemDate = new Date(item.dateCreated)
            return (
              (!value[0] || itemDate >= new Date(value[0])) &&
              (!value[1] || itemDate <= new Date(value[1]))
            )
          }
          if (Array.isArray(value)) {
            return value.includes(item[field])
          }
          return item[field] === value
        })
      }
    })

    setTableData(filteredData)
  }

  useEffect(() => {
    applyFilters()
  }, [filters, columnFilters, mockApiResponse])

  const handleClearFilters = () => {
    setFilters({
      search: '',
      b2bClientId: null,
      purchaseOrder: '',
      b2bOrderNumber: ''
    })
    setColumnFilters({})
  }

  return (
    <div>
      <Filters
        filters={filters}
        setFilters={setFilters}
        filterOptions={filterOptions}
        onClear={handleClearFilters}
      />
      <div style={{marginTop: '20px'}}>
        <Table
          tableInfo={tableData}
          tableColumns={tableColumns}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalItems={tableData.length}
          loading={false}
          onRowClick={() => {}}
          dataType={'transfers'}
        />
      </div>
    </div>
  )
}

export default TransfersPage
