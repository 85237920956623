/**
 * @typedef {Object} Column
 * @property {string} field - The field identifier
 * @property {string} header - Column header text
 * @property {string} align - Alignment of the column content
 * @property {string} [type] - Type of the column (e.g., 'date-time')
 * @property {string} [columnFilter] - Type of filter ('text', 'date', 'multiple')
 * @property {string} [filterName] - Name of the filter
 * @property {boolean} [filter] - Whether the column is filterable
 * @property {Array<Object>} [filters] - Filter options for multiple-type filters
 * @property {string} [left] - Left alignment override
 */

/**
 * @typedef {Object} TableConfig
 * @property {Array<Column>} columns - Array of column configurations
 * @property {Array<Object>} data - Table data rows
 * @property {number} size - Page size for pagination
 * @property {Object} scroll - Scrolling configuration
 * @property {boolean} scroll.scrollable - Whether table is scrollable
 * @property {string} scroll.scrollableHeight - Height of scrollable area
 * @property {boolean} footer - Whether to show table footer
 * @property {Object} editrow - Edit row configuration
 * @property {boolean} editrow.hasEdit - Whether rows are editable
 * @property {Object} checkboxes - Checkbox configuration
 * @property {boolean} checkboxes.checked - Whether checkboxes are checked
 */

/**
 * Renders the column configuration for the stock history modal
 * @param {Array<Object>} movementTypes - Available movement types for filtering
 * @param {Array<Object>} warehouses - Available warehouses for filtering
 * @param {Array<Object>} user - Available users for filtering
 * @param {Object} data - Table data with rows property
 * @param {number} pageSize - Number of rows per page
 * @returns {TableConfig} Complete table configuration object
 */

export const createStockHistoryModalColumns = (
  movementTypes,
  warehouses,
  user,
  data,
  pageSize
) => ({
  columns: [
    {
      field: 'pcwMovementId',
      header: 'ID',
      align: 'space-between',
      columnFilter: 'text',
      filterName: 'pcwMovementId',
      filter: true
    },
    {
      type: 'date-time',
      field: 'date',
      header: 'Date',
      align: 'space-between',
      columnFilter: 'date',
      filterName: 'date',
      filter: true
    },
    {
      field: 'movementTypeName',
      header: 'Movement Type',
      align: 'space-between',
      columnFilter: 'multiple',
      filterName: 'movementType',
      filter: true,
      filters: movementTypes
    },
    {
      field: 'warehouseName',
      header: 'Warehouse',
      align: 'space-between',
      columnFilter: 'multiple',
      filterName: 'warehouseType',
      filter: true,
      filters: warehouses
    },
    {
      field: 'resultingStock',
      header: 'Init stock',
      align: 'center'
    },
    {
      field: 'quantity',
      header: 'Qty',
      align: 'center'
    },
    {
      field: 'finalStock',
      header: 'Final stock',
      align: 'center'
    },
    {
      field: 'users',
      header: 'User',
      align: 'space-between',
      columnFilter: 'multiple',
      filterName: 'users',
      filter: true,
      filters: user,
      left: 'left'
    }
  ],
  data: data?.rows,
  size: pageSize,
  scroll: {
    scrollable: true,
    scrollableHeight: `${window.innerHeight - 360}px`
  },
  footer: false,
  editrow: {
    hasEdit: false
  },
  checkboxes: {
    checked: false
  }
})
