import React, {useEffect, useMemo, useState} from 'react'

import {useTranslation} from 'react-i18next'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'

import {GetUsersWithMovements} from '../../../infra/requests/BaseRequests'
import {
  GetAllWarehouses,
  GetMovementTypeList,
  GetStockHistory
} from '../../../infra/requests/LogisticsRequests'
import {GetTranslationNameFromArray} from '../../../infra/translations/AvailableTranslations'
import Table from '../../Common/Table/index'
import Alert from '../Alerts/index'
import ButtonIcon from '../Buttons/ButtonIcon'
import {createStockHistoryModalColumns} from './stock-hitory-modal/stockHistoryModalColumnConfig'

const MAXIMUM_EXPORT_LINES = 1000

//FIXME when active language is set up
const TEMP_LANGUAGE_ID = 2

const StockHistoryModal = ({isOpened, toggle, item}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(12)
  const [data, setData] = useState()
  const [movementTypes, setMovementTypes] = useState()
  const [warehouses, setWarehouses] = useState()
  const [user, setUsers] = useState()
  const [columnFilters, setColumnFilters] = useState({
    pcwMovementId: '',
    date: [],
    movementType: [],
    warehouseType: [],
    users: []
  })
  const [alertVisible, setAlertVisible] = useState(false)

  const {t} = useTranslation()

  useEffect(() => {
    const getMovementTypes = async () => {
      const {success, data} = await GetMovementTypeList()
      if (success) {
        const array = data?.items?.map(item => ({
          value: item?.movementTypeId,
          label: item?.movementTypeTranslation[1].name
        }))
        setMovementTypes(array)
      }
    }

    const getWarehousesTypes = async () => {
      const {success, data} = await GetAllWarehouses()
      if (success) {
        const array = data?.items?.map(item => ({
          value: item?.warehouseId,
          label: item?.contactName
        }))
        setWarehouses(array)
      }
    }

    const getUsers = async () => {
      const {success, data} = await GetUsersWithMovements()
      if (success) {
        const array = data?.items?.map(item => ({
          value: item?.userId,
          label: item?.fullName
        }))
        setUsers(array)
      }
    }

    getUsers()
    getMovementTypes()
    getWarehousesTypes()
  }, [])

  const handleStockHistory = useMemo(
    () => async () => {
      if (!isOpened) return
      try {
        const {success, data} = await GetStockHistory({
          ...item,
          exportxls: false,
          itemsperpage: pageSize,
          page: page,
          pcwMovementId: columnFilters?.pcwMovementId,
          datefrom: columnFilters?.date[0] ? columnFilters?.date[0] : undefined,
          dateto: columnFilters?.date[1] ? columnFilters?.date[1] : undefined,
          MovementTypeIds: columnFilters?.movementType,
          WarehouseIds: columnFilters?.warehouseType,
          CreatedBy: columnFilters?.users
        })

        if (success) {
          const list = data?.rows?.map(item => ({
            pcwMovementId: item.pcwMovementId,
            date: item.date,
            movementTypeName: item.movementType.movementTypeTranslation[1].name,
            movementTypeId: item.movementType.movementTypeId,
            quantity: item.quantity,
            resultingStock: item.resultingStock,
            warehouseName: item.warehouseName,
            finalStock: item.resultingStock + item.quantity,
            users: item.createdBy.fullName
          }))

          setData({
            numberOfRows: data.numberOfRows,
            pageNumber: data.pageNumber,
            totalNumberOfRows: data.totalNumberOfRows,
            totalPages: data.totalPages,
            rows: list
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    [columnFilters, pageSize]
  )

  useEffect(() => {
    handleStockHistory()
  }, [handleStockHistory])

  const handleDownloadModal = async () => {
    try {
      const authToken = localStorage.getItem('authToken')

      const response = await fetch(
        `${window.env.REACT_APP_API}/Logistics/GetStockHistory`,
        {
          method: 'POST',
          body: JSON.stringify({
            productid: 17,
            sizeid: 25,
            colorid: 21,
            productwidthid: 1,
            exportxls: true,
            itemsperpage: pageSize,
            page: page,
            pcwMovementId: columnFilters?.pcwMovementId,
            datefrom: columnFilters?.date[0]
              ? columnFilters?.date[0]
              : undefined,
            dateto: columnFilters?.date[1] ? columnFilters?.date[1] : undefined,
            MovementTypeIds: columnFilters?.movementType,
            WarehouseIds: columnFilters?.warehouseType,
            CreatedBy: columnFilters?.users
          }),
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.status === 200) {
        const blob = await response.blob()
        const reader = new FileReader()

        reader.onload = event => {
          const csvContent = event.target.result
          const rowCount = csvContent.split('\n').length

          if (rowCount <= MAXIMUM_EXPORT_LINES) {
            const url = window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = 'tts.xls' // specify the file extension
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          } else {
            setAlertVisible(true) // Exibe o alerta se houver mais de 1.000 linhas
            setTimeout(() => {
              setAlertVisible(false)
            }, 5000)
          }
        }

        reader.readAsText(blob)
      } else {
        console.log(`Error: ${response.statusText}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Modal
        style={{minWidth: 400}}
        size="xl"
        isOpen={isOpened}
        toggle={() => {
          setColumnFilters({
            pcwMovementId: '',
            date: [],
            movementType: [],
            warehouseType: [],
            users: []
          })
          toggle()
        }}
        centered>
        <ModalHeader className="modal-title" style={{width: '100%'}}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <div>{t('STOCK_HISTORY')}</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                toggle(false)
                setColumnFilters({
                  pcwMovementId: '',
                  date: [],
                  movementType: [],
                  warehouseType: [],
                  users: []
                })
              }}>
              <ButtonIcon type="noBorder" icon="xIcon" color="black" />
            </div>
          </div>
        </ModalHeader>

        <ModalBody style={{padding: 0}} className="no-padding">
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 15,
              paddingBottom: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <div>
              <div className="heading_3">
                {GetTranslationNameFromArray(
                  item?.productTranslation,
                  TEMP_LANGUAGE_ID
                )}
              </div>
              <div
                className="heading_6"
                style={{color: 'rgba(68, 79, 89, 0.60)'}}>
                {item?.reference}
              </div>
            </div>
            <ButtonIcon
              type="secondary"
              icon="xls"
              size={[21, 21]}
              onClick={() => handleDownloadModal()}
            />
          </div>
          <div
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'flex-start',
              flexDirection: 'column',
              minHeight: 379
            }}>
            {alertVisible && (
              <Alert
                color="danger"
                text="Não é possivel exportação total dos dados."
                additional={false}
                borderless={true}
                heading="Aviso"
              />
            )}
            <Table
              minHeight={462}
              totalItems={data?.totalNumberOfRows}
              currentPage={page}
              setCurrentPage={setPage}
              rowsPerPage={pageSize}
              setRowsPerPage={setPageSize}
              tableColumns={createStockHistoryModalColumns(
                movementTypes,
                warehouses,
                user,
                data,
                pageSize
              )}
              setList={setData}
              tableInfo={data?.rows}
              small
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              paginationOptions={[
                {label: '12', value: 12},
                {label: '24', value: 24},
                {label: '48', value: 48}
              ]}
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default StockHistoryModal
