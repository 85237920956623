import React from 'react'

import {useTranslation} from 'react-i18next'

import withRouter from '../withRouter'

const BreadCrumb = ({breadcrumbData, router}) => {
  const {t} = useTranslation()

  return (
    <div className="row-bread">
      <div
        style={{paddingTop: 8}}
        className="page-title-box-2 d-sm-flex align-items-center justify-content-between">
        <div className="mb-sm-0 page-title-main">
          {t(breadcrumbData.pageTitle)}
        </div>
        <div className="page-title-right">
          <ol className="breadcrumb m-0">
            <li
              className="breadcrumb-item"
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'
              }}>
              <div onClick={() => router.navigate('/')}>
                <i
                  style={{fontSize: 13, color: '#64A0E3', marginTop: 2}}
                  className="ri-home-5-fill"></i>
              </div>
            </li>
            {breadcrumbData.items.map((breadcrumb, index) =>
              index !== 0 ? (
                <div
                  style={{display: 'flex', flexDirection: 'row'}}
                  key={index}>
                  <i
                    key={index}
                    className="mdi mdi-chevron-right"
                    style={{
                      width: 15,
                      color: '#878a99',
                      marginRight: 5,
                      marginLeft: 5,
                      marginTop: 2
                    }}
                  />
                  <li
                    onClick={() =>
                      index !== breadcrumbData.items.length - 1
                        ? router.navigate(breadcrumb.url)
                        : undefined
                    }
                    style={{
                      cursor:
                        index !== breadcrumbData.items.length - 1
                          ? 'pointer'
                          : 'default',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className="breadcrumb-item active">
                    <span style={{marginTop: 1}}>{t(breadcrumb.title)}</span>
                  </li>
                </div>
              ) : (
                <div
                  style={{display: 'flex', flexDirection: 'row'}}
                  key={index}>
                  <i
                    key={index}
                    className="mdi mdi-chevron-right"
                    style={{
                      width: 15,
                      color: '#878a99',
                      marginRight: 5,
                      marginLeft: 5,
                      marginTop: 2
                    }}
                  />
                  <li
                    onClick={() => router.navigate(breadcrumb.url)}
                    className="breadcrumb-item parent-option"
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <span style={{marginTop: 1}}>{t(breadcrumb.title)}</span>
                  </li>
                </div>
              )
            )}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default withRouter(BreadCrumb)
