export const mockApiResponse = {
  transfers: {
    items: [
      {
        transferId: 123456,
        b2bClientName: 'Skypro',
        conclusionLabel: 'Concluded',
        originWarehouseName: 'Flyflair SKU Production',
        destinyWarehouseName: 'Flyflair SKU Quarantine',
        totalUnits: 12,
        dateCreated: '2025-01-06T09:16:08.957',
        createdByUser: 'Daniel Gaspar'
      },
      {
        transferId: 123457,
        b2bClientName: 'AirTech',
        conclusionLabel: 'Pending',
        originWarehouseName: 'AirTech Main Warehouse',
        destinyWarehouseName: 'AirTech Distribution Center',
        totalUnits: 25,
        dateCreated: '2025-01-07T14:30:22.123',
        createdByUser: 'Maria Silva'
      }
    ],
    totalItems: 2
  },
  b2bClients: [
    {b2bClientId: 1, name: 'Skypro'},
    {b2bClientId: 2, name: 'AirTech'}
  ],
  conclusionStatuses: [
    {concluded: 0, conclusionLabel: 'Pending'},
    {concluded: 1, conclusionLabel: 'Concluded'}
  ],
  originWarehouses: [
    {warehouseId: 456, name: 'Flyflair SKU Production'},
    {warehouseId: 457, name: 'AirTech Main Warehouse'}
  ],
  destinyWarehouses: [
    {warehouseId: 458, name: 'Flyflair SKU Quarantine'},
    {warehouseId: 459, name: 'AirTech Distribution Center'}
  ],
  users: [
    {
      userId: '16D3D99C-F378-4FC1-893F-07165DA852C2',
      userName: 'daniel.diogo@ad-pulse.com',
      fullName: 'Daniel Gaspar'
    },
    {
      userId: '27E4E00D-G489-5GD2-904G-18276EB963D3',
      userName: 'maria.silva@ad-pulse.com',
      fullName: 'Maria Silva'
    }
  ],
  dateInterval: {
    from: '2025-01-06T09:16:08.957',
    to: '2025-04-20T04:20:00.000'
  }
}
