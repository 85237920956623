import {useEffect, useState} from 'react'

import {Combobox, Popover, useCombobox} from '@mantine/core'
import {useTranslation} from 'react-i18next'
import SimpleBar from 'simplebar-react'

import Icon from '../../icon'

const DropDown = ({children, onChange, value, options, searchable, top}) => {
  const {t} = useTranslation()
  const [search, setSearch] = useState('')
  const [selectedItem, setSelectedItem] = useState(value)
  const [opened, setOpened] = useState(false)

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
      setSearch('')
    },

    onDropdownOpen: () => {
      searchable && combobox.focusSearchInput()
    }
  })

  useEffect(() => {
    if (selectedItem !== '') {
      onChange(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    setSelectedItem(value)
  }, [value])

  const currentOptions = options?.filter(item =>
    item?.label?.toLowerCase().includes(search?.toLowerCase().trim())
  )

  const handleKeyDown = event => {
    if (currentOptions.length === 1 && event.key === 'Enter') {
      setSelectedItem(currentOptions[0].value)
      setOpened(false)
    }
  }

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      position="bottom-start"
      offset={top || 5}
      width="auto"
      withinPortal={true}>
      <Popover.Target>
        <div onClick={() => setOpened(prev => !prev)}>{children}</div>
      </Popover.Target>

      <Popover.Dropdown style={{padding: 0}}>
        <Combobox
          store={combobox}
          onOptionSubmit={val => {
            setSelectedItem(val)
            setOpened(false)
          }}>
          {searchable && (
            <Combobox.Search
              value={search}
              onKeyDown={e => handleKeyDown(e)}
              onChange={event => setSearch(event.currentTarget.value)}
              placeholder={`${t('SEARCH')}...`}
              rightSectionPointerEvents={search === '' ? 'none' : 'all'}
              rightSection={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: 24
                  }}>
                  {search !== '' ? (
                    <div
                      onMouseDown={event => event.preventDefault()}
                      onClick={() => setSearch('')}
                      aria-label="Clear value">
                      <Icon
                        className="comboBox-close-icon"
                        icon="closeIcon"
                        size={[18, 18]}
                      />
                    </div>
                  ) : (
                    <Icon icon="searchIcon" size={[15, 15]} />
                  )}
                </div>
              }
            />
          )}

          <SimpleBar style={{maxHeight: '320px'}} autoHide={false}>
            <Combobox.Options>
              {currentOptions?.length > 0 ? (
                currentOptions?.map((item, index) => (
                  <Combobox.Option
                    value={item.value}
                    key={index}
                    active={
                      selectedItem === item.value ||
                      (currentOptions?.length === 1 && index === 0)
                        ? true
                        : false
                    }
                    selected={
                      selectedItem === item.value ||
                      (currentOptions?.length === 1 && index === 0)
                        ? true
                        : false
                    }>
                    {item.icon && (
                      <Icon
                        className={
                          selectedItem === item.value && 'combo-box-option-icon'
                        }
                        icon={item.icon}
                        size={[15, 15]}
                        style={{marginRight: 6}}
                      />
                    )}
                    {item.label}
                  </Combobox.Option>
                ))
              ) : (
                <Combobox.Empty>{t('NO_RESULTS_FOUND')}</Combobox.Empty>
              )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox>
      </Popover.Dropdown>
    </Popover>
  )
}

export default DropDown
