import {Field, useFormState} from 'react-final-form'

import TextInputAdaptor from '../../../../../../Components/Common/FormComponentAdaptors/TextInputAdaptor'
import {useGeneralInformation} from '../../../../hooks/useGeneralInformation'

const GeneralInformation = () => {
  const {values} = useFormState()
  const {textInputData, selectInputData, multipleSelectInputData} =
    useGeneralInformation(values)

  return (
    <>
      <div className="row g-1">
        {selectInputData.map(item => {
          return (
            <div className="input-wrap" key={item.name}>
              <Field {...item} />
            </div>
          )
        })}
      </div>
      <div className="row g-1 mt-1">
        {multipleSelectInputData.map(item => {
          return (
            <div className="col-md-4 select-wrap" key={item.name}>
              <Field {...item} />
            </div>
          )
        })}
      </div>
      <div className="row g-1 mt-4">
        {textInputData.map(item => {
          return (
            <div className="input-wrap packages-box" key={item.name}>
              <Field
                {...item}
                render={props => <TextInputAdaptor {...props} type="number" />}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

export default GeneralInformation
