import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Card} from 'reactstrap'

import Loader from '../../../../../Components/Common/Loader'
import GeneralInformation from './general-information/GeneralInformation'
import NameAndDescription from './name-and-description/NameAndDescription'
import SEO from './seo/SEO'

const Properties = () => {
  const isLoading = useSelector(state => state?.Info?.isLoading)
  const {t} = useTranslation()

  return (
    <Card className="card-form p-3 mb-5">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="title heading_1 mt-2 pb-3 mb-4">
            {t('GENERAL_INFORMATION')}
          </div>
          <GeneralInformation />
          <div className="title heading_1 pb-3 mb-4 mt-5">
            {t('NAME_AND_DESCRIPTION')}
          </div>
          <NameAndDescription />
          <div className="title heading_1 pb-3 mb-4 mt-5">{t('SEO')}</div>
          <div className="mb-5">
            <SEO />
          </div>
        </>
      )}
    </Card>
  )
}

export default Properties
