import {useEffect, useState} from 'react'

import {Combobox, useCombobox} from '@mantine/core'
import {useTranslation} from 'react-i18next'

import Icon from '../../icon'

const DropDownText = ({children, onChange, value, searchable = true, top}) => {
  const {t} = useTranslation()
  const [search, setSearch] = useState(value || '')
  const [selectedItem, setSelectedItem] = useState(value)

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption()
      combobox.focusTarget()
    },

    onDropdownOpen: () => {
      searchable && combobox.focusSearchInput()
    }
  })

  useEffect(() => {
    if (selectedItem !== '') {
      onChange(selectedItem)
    }
  }, [selectedItem])

  useEffect(() => {
    setSelectedItem(value)
  }, [value])

  const handleKeyDown = event => {
    if (search !== '' && event.key === 'Enter') {
      onChange(search)
      combobox.closeDropdown()
    }
  }
  return (
    <Combobox
      withinPortal={false}
      store={combobox}
      onOptionSubmit={val => {
        setSelectedItem(val)
        combobox.closeDropdown()
      }}
      position="bottom"
      middlewares={{flip: false, shift: false}}>
      <Combobox.Target
        onClick={() => {
          combobox.toggleDropdown()
        }}>
        {children}
      </Combobox.Target>

      <Combobox.Dropdown
        style={{
          width: 'max-content !important',
          marginTop: top || 0,
          right: 0,
          zIndex: 99999
        }}>
        <Combobox.Search
          value={search}
          onKeyDown={e => handleKeyDown(e)}
          onChange={event => setSearch(event.currentTarget.value)}
          placeholder={`${t('SEARCH')}...`}
          rightSectionPointerEvents={search === '' ? 'none' : 'all'}
          rightSection={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginRight: 24
              }}>
              {search !== '' ? (
                <div
                  onMouseDown={event => event.preventDefault()}
                  onClick={() => {
                    setSearch('')
                    onChange('')
                  }}
                  aria-label="Clear value">
                  <Icon
                    className="comboBox-close-icon"
                    icon="closeIcon"
                    size={[18, 18]}
                  />
                </div>
              ) : (
                <Icon icon="searchIcon" size={[15, 15]} />
              )}
            </div>
          }
        />
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default DropDownText
