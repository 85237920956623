import React from 'react'

import {FileInput} from '@mantine/core'
import {useTranslation} from 'react-i18next'
import {Label} from 'reactstrap'

const FilesInput = ({
  label,
  required,
  name,
  subtitle,
  value,
  id,
  onChange,
  multiple,
  invalid,
  placeholder,
  disabled
}) => {
  const {t} = useTranslation()
  return (
    <div className="mt-2 costum-file-input">
      {label && (
        <Label className="heading_4">
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <FileInput
        leftSection={
          <label
            htmlFor={disabled ? '' : id}
            className="heading_6 input-file-wrapper"
            style={{
              cursor: `${disabled ? 'not-allowed' : 'pointer'}`,
              borderRight: `1px solid ${disabled ? '#F2F2F2' : '#DEDEDE'}`
            }}>
            {!disabled && t('CHOOSE_FILE')}{' '}
          </label>
        }
        multiple={multiple}
        value={value}
        disabled={disabled}
        onChange={onChange}
        name={name}
        id={id}
        error={invalid}
        className="heading_6 form-control-plaintext"
        placeholder={placeholder}
        valueComponent={() => {
          if (multiple) {
            return (
              <>
                {value.length > 1 ? (
                  <div>
                    {value.length} {t('FILES_SELECTED')}
                  </div>
                ) : (
                  <div>{value[0]?.name}</div>
                )}
              </>
            )
          } else {
            return <div>{value.name}</div>
          }
        }}
      />
      {subtitle && (
        <div style={{marginTop: 5}} className="bodyText_11 input_subtitle_text">
          {subtitle}
        </div>
      )}
    </div>
  )
}

export default FilesInput
