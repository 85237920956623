import React from 'react'

import {Navigate} from 'react-router-dom'

import Alerts from '../pages/Alerts'
import Products from '../pages/Articles/Products'
import ArticlesDetails from '../pages/ArticlesDetails'
//login
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import ForgotPassword from '../pages/Authentication/forgot-password/ForgotPassword'
import ResetPasswordFlow from '../pages/Authentication/reset-password/ResetPasswordFlow'
import BasicElements from '../pages/BasicElements'
import Buttons from '../pages/Buttons'
import Colours from '../pages/Colours'
// IFrame
import IFrame from '../pages/IFrame'
import IconsPage from '../pages/Icons'
import Placeholder from '../pages/Placeholder'
//SmartOrders
import SmartOrders from '../pages/SmartOrders'
import TablesPage from '../pages/TablesPage/index'
import Transfers from '../pages/Transfers'
// import Register from "../pages/Authentication/Register";
import Typography from '../pages/Typography'

const authProtectedRoutes = [
  {path: '/iframe', component: <IFrame />},
  {path: '/tables', component: <TablesPage />},
  {path: '/colours', component: <Colours />},
  {path: '/alerts', component: <Alerts />},
  {path: '*', component: <Navigate to="/iframe?url=#" />},
  {path: '/typography', component: <Typography />},
  {path: '/icons', component: <IconsPage />},
  {path: '/basic-elements', component: <BasicElements />},
  {path: '/articles', component: <Products />},
  {path: '/buttons', component: <Buttons />},
  {path: '/placeholder', component: <Placeholder />},
  {path: '/articlesDetails/:productId', component: <ArticlesDetails />},
  {path: '/smartOrders', component: <SmartOrders />},
  {path: '/transfers', component: <Transfers />}
]

const publicRoutes = [
  // Authentication
  {path: '/login', component: <Login />},
  {path: '/forgot-password', component: <ForgotPassword />},
  {path: '/reset-password', component: <ResetPasswordFlow />},
  {path: '/logout', component: <Logout />}
  // { path: "/register", component: <Register /> },
]

export {authProtectedRoutes, publicRoutes}
