import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const IFrame = () => {
  const iframeRef = useRef(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const authToken = localStorage.getItem('authToken')
  const [currentLanguage, setCurrentLanguage] = useState(() =>
    JSON.parse(localStorage.getItem('I18N_LANGUAGE') || '{}'),
  )
  const location = useLocation()
  const navigate = useNavigate()

  const handleIFrameLink = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)
    const urlParam = queryParams.get('url')
    const baseUrl = urlParam
      ? `${window.env.REACT_APP_IFRAME_PARENT_URL}/${urlParam}`
      : window.env.REACT_APP_IFRAME_PARENT_URL

    const url = new URL(baseUrl)
    url.searchParams.set('languageId', currentLanguage?.id?.toString() || '')

    queryParams.forEach((value, key) => {
      if (key !== 'languageId' && key !== 'url') {
        url.searchParams.set(key, value)
      }
    })

    return `${url.toString()}${location.hash}`
  }, [location.search, location.hash, currentLanguage])

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = handleIFrameLink()
    }
  }, [handleIFrameLink])

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguage = JSON.parse(localStorage.getItem('I18N_LANGUAGE') || '{}')
      setCurrentLanguage(newLanguage)
      updateIframeSrc()
    }

    const originalSetItem = localStorage.setItem
    localStorage.setItem = function (key) {
      originalSetItem.apply(this, arguments)
      if (key === 'I18N_LANGUAGE') {
        handleLanguageChange()
      }
    }

    window.addEventListener('storage', (e) => {
      if (e.key === 'I18N_LANGUAGE') {
        handleLanguageChange()
      }
    })

    return () => {
      localStorage.setItem = originalSetItem
      window.removeEventListener('storage', handleLanguageChange)
    }
  }, [updateIframeSrc])

  const sendMessageToIframe = useCallback(() => {
    if (iframeRef.current?.contentWindow) {
      const message = {
        type: 'authentication',
        token: authToken,
        isHeaderVisible: 'false'
      }
      iframeRef.current.contentWindow.postMessage(
        message,
        window.env.REACT_APP_IFRAME_PARENT_URL
      )
    }
  }, [authToken])

  useEffect(() => {
    if (iframeLoaded) {
      sendMessageToIframe()
    }
  }, [iframeLoaded, sendMessageToIframe])

  const handleIframeLoad = useCallback(() => {
    setIframeLoaded(true)
    const frame = document.getElementById('iframe')
    if (frame) {
      frame.style.height = 'calc(100vh - 77px)'
      frame.style.width = '100%'
    }
  }, [])

  useEffect(() => {
    const handleMessage = event => {
      if (event?.data?.message === 'logout') {
        navigate('/logout')
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [navigate])

  return (
    <div
      style={{
        height: 'calc(100vh - 77px)',
        width: '100%',
        marginTop: 77,
        overflow: 'hidden'
      }}>
      <iframe
        id='iframe'
        style={{
          margin: 0,
          padding: 0,
          height: '100%',
          width: '100%',
          border: 'none'
        }}
        ref={iframeRef}
        onLoad={handleIframeLoad}
        src={handleIFrameLink()}
        title='Content iframe'
      />
    </div>
  )
}

export default IFrame
