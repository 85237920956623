import React from 'react'

import icons from '../../../assets/icons'
import {SvgWrapper} from './styles'

const Icon = ({icon, ...forwardProps}) => {
  const selectedIcon = icons[icon]

  if (!selectedIcon) {
    return null
  }

  return (
    <SvgWrapper {...forwardProps} {...selectedIcon}>
      {selectedIcon.icon}
    </SvgWrapper>
  )
}

export default Icon
