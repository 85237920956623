// index.js
import React from 'react'

const Index = ({data, header}) => {
  // Conditional rendering based on the header
  let formattedData
  switch (header) {
    case 'Total Value':
      formattedData = <span>{data}€</span>
      break
    case 'Unit Value':
      formattedData = <span>{data}€</span>
      break
    case 'Stock':
      formattedData = (
        <span>
          {data}
          <br />
          <div>Unit</div>
        </span>
      )
      break
    // Add more cases for other headers if needed
    default:
      formattedData = data // Default formatting
  }

  return <div style={{textAlign: 'center'}}>{formattedData}</div>
}

export default Index
