import {useSelector} from 'react-redux'
import {Card} from 'reactstrap'

import Loader from '../../../../../Components/Common/Loader'
import SKUTable from './sku-table/SKUTable'

const SKUTab = () => {
  const isLoading = useSelector(state => state?.Info?.isLoading)

  return (
    <Card className="card-form p-3 mb-5">
      {isLoading ? <Loader /> : <SKUTable />}
    </Card>
  )
}

export default SKUTab
