import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import MultipleSelectAdaptor from '../../../Components/Common/FormComponentAdaptors/MultipleSelectorAdaptor'
import SelectInputAdaptor from '../../../Components/Common/FormComponentAdaptors/SelectInputAdaptor'


const businessOptions = [
  { value: false, name: 'B2B' },
  { value: true, name: 'B2C' }
]

export const useGeneralInformation = values => {
  const {
    productTypes,
    productCategories,
    productCharacteristics,
    productTechnologies
  } = useSelector(state => state?.Info?.data)
  const { t } = useTranslation()

  const parentCategories = productCategories?.filter(
    cat => !cat.parentCategoryId
  )

  const categories = productCategories.filter(cat => {
    return cat.parentCategoryId === values.gender
  })

  const subCategories = productCategories.filter(cat => {
    return cat.parentCategoryId === values.category
  })

  const textInputData = [
    {
      name: 'packageWidth',
      label: t('PACKAGE_WIDTH_CM')
    },
    {
      name: 'packageHeight',
      label: t('PACKAGE_HEIGHT_CM')
    },
    {
      name: 'packageDepth',
      label: t('PACKAGE_DEPTH_CM')
    },
    {
      name: 'packageWeight',
      label: t('PACKAGE_WIDTH_CM')
    }
  ]

  const selectInputData = [
    {
      name: 'business',
      options: businessOptions,
      label: t('BUSINESS'),
      dataKey: 'value',
      placeholder: t('SELECT_BUSINESS'),
      component: SelectInputAdaptor
    },
    {
      name: 'type',
      options: productTypes,
      dataKey: 'productTypeId',
      dataLabel: 'productTypeTranslation',
      label: t('TYPE'),
      placeholder: t('SELECT_TYPE'),
      component: SelectInputAdaptor
    },
    {
      name: 'gender',
      options: parentCategories,
      dataLabel: 'categoryTranslation',
      label: t('GENDER'),
      dataKey: 'categoryId',
      placeholder: t('SELECT_GENDER'),
      component: SelectInputAdaptor
    },
    {
      name: 'category',
      options: categories,
      disabled: !values.gender,
      dataKey: 'categoryId',
      dataLabel: 'categoryTranslation',
      label: t('CATEGORY'),
      placeholder: t('SELECT_CATEGORY'),
      component: SelectInputAdaptor
    },
    {
      name: 'subCategory',
      options: subCategories,
      disabled: !values.category,
      dataKey: 'categoryId',
      dataLabel: 'categoryTranslation',
      label: t('SUB_CATEGORY'),
      placeholder: t('SELECT_SUB_CATEGORY'),
      component: SelectInputAdaptor
    }
  ]

  const multipleSelectInputData = [
    {
      name: 'characteristics',
      multiple: true,
      label: t('CHARACTERISTICS'),
      hasCloseOnOption: true,
      searchable: true,
      placeholder: t('SELECT_CHARACTERISTICS'),
      options: productCharacteristics,
      dataLabel: 'characteristicTranslation',
      dataKey: 'characteristicId',
      component: MultipleSelectAdaptor
    },
    {
      name: 'technologies',
      multiple: true,
      label: t('TECHNOLOGIES'),
      hasCloseOnOption: true,
      searchable: true,
      placeholder: t('SELECT_TECHNOLOGIES'),
      options: productTechnologies,
      dataLabel: 'technologyTranslation',
      dataKey: 'technologyId',
      component: MultipleSelectAdaptor
    }
  ]

  return {
    textInputData,
    selectInputData,
    multipleSelectInputData
  }
}
