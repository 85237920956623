import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle, Form, Input } from 'reactstrap'
import { createSelector } from 'reselect'
import SimpleBar from 'simplebar-react'

import FullScreenDropdown from '../Components/Common/FullScreenDropdown'
import LanguageDropdown from '../Components/Common/LanguageDropdown'
import Loader from '../Components/Common/Loader'
import NotificationDropdown from '../Components/Common/NotificationDropdown'
import ProfileDropdown from '../Components/Common/ProfileDropdown'
//import Components
import SearchOption from '../Components/Common/SearchOption'
import WebAppsDropdown from '../Components/Common/WebAppsDropdown'
import withRouter from '../Components/Common/withRouter'
import Placeholder from '../assets/imagesNew/placeholder.jpg'
import { GetOrdersB2B } from '../infra/requests/OrdersB2BRequests'
import { GetB2BProducts } from '../infra/requests/ProductsRequests'
import {
  ConstructQuery,
  ConstructSort
} from '../shared/logic/queries/EndpointQueries'
// import LightDark from "../Components/Common/LightDark";

import { changeSidebarVisibility } from '../slices/thunks'

//const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
const Header = ({ headerClass, router }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [products, setProducts] = useState([])
  const [hasMoreProducts, setHasMoreProducts] = useState(false)
  const hasData = orders.length > 0 || products.length > 0
  const selectDashboardData = createSelector(
    state => state.Layout,
    state => ({
      sidebarVisibilitytype: state.sidebarVisibilitytype
    })
  )

  // Inside your component
  const { sidebarVisibilitytype } = useSelector(selectDashboardData)

  const [search, setSearch] = useState(false)
  const toogleSearch = () => {
    setSearch(!search)
  }

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth
    dispatch(changeSidebarVisibility('show'))

    if (windowSize > 767)
      document.querySelector('.hamburger-icon').classList.toggle('open')

    //For collapse horizontal menu
    if (document.documentElement.getAttribute('data-layout') === 'horizontal') {
      document.body.classList.contains('menu')
        ? document.body.classList.remove('menu')
        : document.body.classList.add('menu')
    }

    //For collapse vertical and semibox menu
    if (
      sidebarVisibilitytype === 'show' &&
      (document.documentElement.getAttribute('data-layout') === 'vertical' ||
        document.documentElement.getAttribute('data-layout') === 'semibox')
    ) {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'sm'
          ? document.documentElement.setAttribute('data-sidebar-size', '')
          : document.documentElement.setAttribute('data-sidebar-size', 'sm')
      } else if (windowSize > 1025) {
        document.body.classList.remove('vertical-sidebar-enable')
        document.documentElement.getAttribute('data-sidebar-size') === 'lg'
          ? document.documentElement.setAttribute('data-sidebar-size', 'sm')
          : document.documentElement.setAttribute('data-sidebar-size', 'lg')
      } else if (windowSize <= 767) {
        document.body.classList.add('vertical-sidebar-enable')
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
    }

    //Two column menu
    if (document.documentElement.getAttribute('data-layout') === 'twocolumn') {
      document.body.classList.contains('twocolumn-panel')
        ? document.body.classList.remove('twocolumn-panel')
        : document.body.classList.add('twocolumn-panel')
    }
  }

  const onChangeData = value => {
    setSearchText(value)
  }

  useEffect(() => {
    var searchOptions = document.getElementById('search-close-options')
    var dropdown = document.getElementById('search-dropdown')
    var searchInput = document.getElementById('search-options')

    if (hasData) {
      dropdown.classList.add('show')
    } else {
      dropdown.classList.remove('show')
    }

    searchInput.addEventListener('focus', function () {
      var inputLength = searchInput.value.length
      if (inputLength > 2 && hasData) {
        dropdown.classList.add('show')
        searchOptions.classList.remove('d-none')
      } else {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })

    searchInput.addEventListener('keyup', function () {
      var inputLength = searchInput.value.length
      if (inputLength > 2 && hasData) {
        dropdown.classList.add('show')
        searchOptions.classList.remove('d-none')
      } else {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })

    searchOptions.addEventListener('click', function () {
      searchInput.value = ''
      dropdown.classList.remove('show')
      searchOptions.classList.add('d-none')
    })

    document.body.addEventListener('click', function (e) {
      if (e.target.getAttribute('id') !== 'search-options') {
        dropdown.classList.remove('show')
        searchOptions.classList.add('d-none')
      }
    })
  }, [hasData])

  useEffect(() => {
    const handleOrdersRequest = async () => {
      try {
        setLoading(true)
        const { success, data } = await GetOrdersB2B(
          1,
          5,
          ConstructQuery({ search: searchText }),
          ConstructSort()
        )

        if (success) {
          setOrders(data.items)
          setHasMoreOrders(data.totalItems > 5 ? true : false)
        }

        setLoading(false)
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }

    const handleProductsRequest = async () => {
      try {
        setLoading(true)
        const { success, data } = await GetB2BProducts(
          1,
          5,
          ConstructQuery({ search: searchText }),
          ConstructSort()
        )

        if (success) {
          setProducts(data.items)
          setHasMoreProducts(data.totalItems > 5 ? true : false)
        }

        setLoading(false)
      } catch (error) {
        console.warn(error)
        setLoading(false)
      }
    }

    if (searchText.length >= 3) {
      handleOrdersRequest()
      handleProductsRequest()
    }
  }, [searchText])

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div className="navbar-header">
            <div className="d-flex">
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon">
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>

              <SearchOption />
            </div>

            <div className="d-flex align-items-center">
              <Dropdown
                isOpen={search}
                toggle={toogleSearch}
                className="d-md-none topbar-head-dropdown header-item">
                <DropdownToggle
                  type="button"
                  tag="button"
                  className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                  <i className="bx bx-search fs-22"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control"
                          placeholder={`${t('SEARCH')}...`}
                          id="search-options"
                          value={searchText}
                          onChange={e => {
                            onChangeData(e.target.value)
                          }}
                        />
                        <span
                          className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                          id="search-close-options"></span>
                      </div>
                      {searchText.length >= 3 && (
                        <SimpleBar style={{ maxHeight: '320px' }}>
                          {loading ? (
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: 320
                              }}>
                              <Loader />
                            </div>
                          ) : (
                            <>
                              {orders.length > 0 && (
                                <>
                                  <div className="dropdown-header mt-2">
                                    <h6 className="text-overflow text-muted mb-2 text-uppercase">
                                      {t('ORDERS')}
                                    </h6>
                                  </div>
                                  <div className="notification-list">
                                    {orders.map((order, index) => (
                                      <div
                                        key={index}
                                        className="dropdown-item notify-item py-2"
                                        style={{ whiteSpace: 'normal' }}
                                        onClick={() => {
                                          setSearchText('')
                                          setSearch(false)
                                          router.navigate(
                                            `/iframe?url=orders/b2b/${order.orderB2bid}`
                                          )
                                        }}>
                                        <div className="d-flex">
                                          <div className="flex-grow-1">
                                            <h6 className="m-0">
                                              {order.number}
                                            </h6>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  {hasMoreOrders && (
                                    <div className="pt-3 pb-1 text-center">
                                      <div
                                        onClick={() => {
                                          setSearchText('')
                                          setSearch(false)
                                          router.navigate(
                                            `/iframe?url=orders?search=${searchText}`
                                          )
                                        }}
                                        className="btn btn-primary btn-sm">
                                        {t('VIEW_ALL_ORDERS')}
                                        <i className="ri-arrow-right-line ms-1"></i>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                              {products.length > 0 && (
                                <>
                                  <div className="dropdown-header mt-2">
                                    <h6 className="text-overflow text-muted mb-2 text-uppercase">
                                      {t('PRODUCTS')}
                                    </h6>
                                  </div>
                                  <div
                                    className="notification-list"
                                    style={{ overflow: 'hidden' }}>
                                    {products.map((product, index) => (
                                      <div
                                        key={index}
                                        onClick={() => {
                                          setSearchText('')
                                          setSearch(false)
                                          router.navigate(
                                            `/iframe?url=products/${product.productId}`
                                          )
                                        }}
                                        className="dropdown-item notify-item py-2"
                                        style={{ whiteSpace: 'normal' }}>
                                        <div className="d-flex">
                                          <div style={{ width: 45, height: 35 }}>
                                            <img
                                              src={
                                                product.productImage.length > 0
                                                  ? product.productImage[0]
                                                    .image.url
                                                  : Placeholder
                                              }
                                              className="me-3 rounded-circle avatar-xs"
                                              alt="product-image"
                                            />
                                          </div>
                                          <div className="flex-grow-1">
                                            <h6 className="m-0">
                                              {
                                                product.productTranslation[0]
                                                  .name
                                              }
                                            </h6>
                                            <span className="fs-11 mb-0 text-muted">
                                              {product.referenceCode}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  {hasMoreProducts && (
                                    <div className="pt-3 pb-1 text-center">
                                      <div
                                        onClick={() => {
                                          setSearchText('')
                                          setSearch(false)
                                          router.navigate(
                                            `/iframe?url=products?search=${searchText}`
                                          )
                                        }}
                                        className="btn btn-primary btn-sm">
                                        {t('VIEW_ALL_PRODUCTS')}
                                        <i className="ri-arrow-right-line ms-1"></i>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </SimpleBar>
                      )}
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown>

              {/* LanguageDropdown */}
              {!loading && <LanguageDropdown />}

              {/* WebAppsDropdown */}
              <WebAppsDropdown />

              {/* FullScreenDropdown */}
              <FullScreenDropdown />

              {/* Dark/Light Mode set */}
              {/* <LightDark
                layoutMode={layoutModeType}
                onChangeLayoutMode={onChangeLayoutMode}
              /> */}

              {/* NotificationDropdown */}
              <NotificationDropdown />

              {/* ProfileDropdown */}
              <ProfileDropdown />
            </div>
          </div>
          <div className="navbar-line">
            <div className="d-flex w-100 justify-content-end align-items-end">
              <div className="triangle-blue" />
              <div className="navbar-line-blue" />
            </div>
            <div className="d-flex flex-row">
              <div className="navbar-line-red" />
              <div className="triangle-red" />
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default withRouter(Header)
