import {combineReducers} from 'redux'

// API Key
import APIKeyReducer from './apiKey/reducer'
// Authentication
import LoginReducer from './auth/login/reducer'
import ProfileReducer from './auth/profile/reducer'
import AccountReducer from './auth/register/reducer'
//Info
import InfoReducer from './info/reducer'
// Front
import LayoutReducer from './layouts/reducer'

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  APIKey: APIKeyReducer,
  Info: InfoReducer
})

export default rootReducer
