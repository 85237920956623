import React from 'react'

import {Button, Input} from 'reactstrap'

import BreadCrumb from '../../Components/Common/Breadcrumb'

const index = () => {
  const breadcrumbData = {
    pageTitle: 'ARTICLE_DETAIL',
    items: [
      {title: 'Article', url: '/iframe'},
      {title: 'Products', url: '/iframe'}
    ]
  }

  return (
    <div className="page-content bg-white">
      <BreadCrumb breadcrumbData={breadcrumbData} />
      <div className="mt-3">
        <h1>Texts</h1>
        <span className="text-primary m-1">Text-primary</span>
        <span className="text-secondary m-1">Text-secondary</span>
        <span className="text-info m-1">Text-info</span>
        <span className="text-success m-1">Text-success</span>
        <span className="text-warning m-1">Text-warning</span>
        <span className="text-danger m-1">Text-danger</span>
        <span className="text-primary opacity-25 m-1">Text-disabled</span>
      </div>

      <div className="mt-3">
        <h1>Borders</h1>
        <div className="d-flex">
          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-primary</h6>
            <Input
              name="password"
              className="form-control border-border-primary pe-5 "
            />
          </div>

          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-secondary</h6>
            <Input
              name="password"
              value={''}
              className="form-control border-border-secondary pe-5"
            />
          </div>
          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-info</h6>
            <Input
              name="password"
              value={''}
              className="form-control pe-5 border-info "
            />
          </div>
          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-success</h6>
            <Input
              name="password"
              value={''}
              className="form-control pe-5 border-success "
            />
          </div>
          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-warning</h6>
            <Input
              name="password"
              value={''}
              className="form-control pe-5 border-warning "
              placeholder=""
            />
          </div>
          <div className="m-1" style={{width: 120}}>
            <h6 className="text-center m-0">border-danger</h6>
            <Input
              name="password"
              value={''}
              className="form-control pe-5 border-danger "
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <h1>Background Colour</h1>
        <div className="d-flex">
          <div>
            <h6 className="text-center m-0">BG-Portal</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-portal m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">BG-Soft-Container</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-container m-1 border"
            />
          </div>

          <div>
            <h6 className="text-center m-0">BG-Info</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-info m-1"
            />
          </div>

          <div>
            <h6 className="text-center m-0">BG-Success</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-success m-1"
            />
          </div>

          <div>
            <h6 className="text-center m-0">BG-Warning</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-warning m-1"
            />
          </div>

          <div>
            <h6 className="text-center m-0">BG-Danger</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-danger m-1"
            />
          </div>

          <div>
            <h6 className="text-center m-0">BG-Sidebar-Brand</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-sidebar-brand m-1"
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <h1>Soft Buttons</h1>
        <Button className="btn-soft-secondary text-primary hover-button-text w-3/4 m-1">
          button
        </Button>
        <Button className="btn-soft-secondary w-3/4 m-1">button</Button>
        <Button className="btn-soft-info w-3/4 m-1">button</Button>
        <Button className="btn-soft-success w-3/4 m-1">button</Button>
        <Button className="btn-soft-warning w-3/4 m-1">button</Button>
        <Button className="btn-soft-danger w-3/4 m-1">button</Button>
      </div>

      <div className="mt-3">
        <h1>Status Colour</h1>
        <div className="d-flex">
          <div>
            <h6 className="text-center m-0">New</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-new m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Manufacturing</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-manufacturing m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Ready to Process</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-ready-to-process m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Ready to ship</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-ready-to-ship m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Ready to pick up</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-ready-to-pickup m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Shipped</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-shipped m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Delivered</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-delivered m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Open Order</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-open-order m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Closed</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-closed m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">Canceled</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-status-canceled m-1"
            />
          </div>
        </div>
      </div>

      <div className="mt-3">
        <h1>Battery bar Colour</h1>

        <div className="d-flex">
          <div>
            <h6 className="text-center m-0">0%-20%</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-battery-20 m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">20%-40%</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-battery-40 m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">40%-60%</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-battery-60 m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">60%-80%</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-battery-80 m-1"
            />
          </div>
          <div>
            <h6 className="text-center m-0">80%-100%</h6>
            <div
              style={{width: 108, height: 30, border: 1, borderRadius: 4}}
              className="bg-battery-100 m-1"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default index
