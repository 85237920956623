import React from 'react'

const Index = ({data}) => {
  return (
    <div style={{textAlign: 'center'}}>
      <span className="bg-primary bg-opacity-25 p-1 rounded border-primary text-primary">
        {data}
      </span>
    </div>
  )
}

export default Index
