import {useCallback, useMemo} from 'react'

import {Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {useTranslation} from 'react-i18next'

import BaseButton from '../../../../../../Components/Common/Buttons/BaseButton'
import SelectInput from '../../../../../../Components/Common/Inputs/SelectInput/SelectInput'
import {ProductTranslationLanguages} from '../../../../../../infra/constants/ProductTranslationLanguages'
import NameAndDescriptionRow from './NameAndDescriptionRow'

const NameAndDescription = () => {
  const {t} = useTranslation()
  return (
    <FieldArray name="nameAndDescription">
      {({fields}) => {
        const availableLanguages = useMemo(() => {
          const usedLanguageIds = fields?.value?.map(field => field.languageId)
          return ProductTranslationLanguages.filter(
            lang => !usedLanguageIds?.includes(lang.languageId)
          )
        }, [fields.value])

        const onRemove = useCallback(
          index => {
            fields.remove(index)
          },
          [fields]
        )

        const onAddTranslation = useCallback(
          selectedValue => {
            const selectedLang = ProductTranslationLanguages.find(
              lang => lang.languageId === selectedValue
            )
            if (selectedLang) {
              fields.push({
                languageId: selectedLang.languageId,
                name: '',
                description: ''
              })
            }
          },
          [fields]
        )

        const showHeader = fields.length > 0

        return (
          <div className="overflow-auto">
            <table>
              <thead>
                <tr>
                  <th className="heading_4">{showHeader && t('LANGUAGE')}</th>
                  <th className="heading_4">{showHeader && t('NAME')}</th>
                  <th className="heading_4">
                    {showHeader && t('DESCRIPTION')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {fields &&
                  fields.map((name, index) => (
                    <NameAndDescriptionRow
                      key={name}
                      name={name}
                      index={index}
                      onRemove={() => onRemove(index)}
                    />
                  ))}
                <tr>
                  <td>
                    <div className="me-4 table-row-width">
                      <Field name="selectedLanguage" component="select">
                        {({input}) => (
                          <SelectInput
                            {...input}
                            placeholder={t('SELECT_LANGUAGE')}
                            options={availableLanguages}
                            dataKey="languageId"
                            disabled={availableLanguages.length === 0}
                          />
                        )}
                      </Field>
                    </div>
                  </td>
                  <td>
                    <Field name="selectedLanguage">
                      {({input}) => {
                        return (
                          <BaseButton
                            disabled={!input.value}
                            onClick={() => {
                              onAddTranslation(input.value)
                              input.onChange('')
                            }}
                            text={t('ADD_TRANSLATION')}
                          />
                        )
                      }}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )
      }}
    </FieldArray>
  )
}

export default NameAndDescription
