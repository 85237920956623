import {useCallback, useState} from 'react'

import {Field, useForm, useFormState} from 'react-final-form'
import {Link} from 'react-router-dom'

import ButtonIcon from '../../../Components/Common/Buttons/ButtonIcon'
import BaseCheckBox from '../../../Components/Common/Inputs/CheckBox'
import {GetTranslationNameFromArray} from '../../../infra/translations/AvailableTranslations'

// FIXME change to activeLanguage once active language is set up
const LANGUAGE_ID = 2

const HISTORY_BUTTON_SIZE = 30

const renderColorCell = rowData => (
  <div style={{display: 'flex', justifyContent: 'left'}}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <span>
        {GetTranslationNameFromArray(
          rowData.color.colorTranslation,
          LANGUAGE_ID
        )}
      </span>
      <span style={{fontWeight: 'bold'}}>{rowData.colorId}</span>
    </div>
  </div>
)

const renderOnlineStoreCell = rowIndex => (
  <div style={{display: 'flex', justifyContent: 'center'}}>
    <Field
      type="select"
      name={`skuSection.productCombination[${rowIndex}].active`}
      component="input">
      {({input}) => <BaseCheckBox {...input} dataKey="active" />}
    </Field>
  </div>
)

const renderProductWidthCell = rowData => (
  <div style={{display: 'flex', justifyContent: 'center'}}>
    <span>
      {GetTranslationNameFromArray(
        rowData.productWidth.productWidthTranslation,
        LANGUAGE_ID
      )}
    </span>
  </div>
)

const renderSizeCell = rowData => (
  <div style={{display: 'flex', justifyContent: 'center'}}>
    <span>{rowData.size.nameEu}</span>
  </div>
)

export const useSKUTable = () => {
  const [historyModalState, setHistoryModalState] = useState({
    isOpen: false,
    item: null
  })
  const {skuSection: {productCombination = []} = {}} = useFormState().values
  const form = useForm()

  const handleHistoryClick = useCallback(rowData => {
    const {colorId, productId, productWidthId, sizeId, product, reference} =
      rowData

    setHistoryModalState({
      isOpen: true,
      item: {
        colorId,
        productId,
        productWidthId,
        sizeId,
        productTranslation: product.productTranslation,
        reference
      }
    })
  }, [])

  const handleModalClose = useCallback(() => {
    setHistoryModalState(prev => ({
      ...prev,
      isOpen: false
    }))
  }, [])

  const renderStockCell = rowData => {
    return (
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: HISTORY_BUTTON_SIZE}} />
        <Link
          className="stock-link"
          style={{display: 'flex', alignItems: 'center'}}
          to={`/iframe?url=logistics?itemBarcode=${rowData.barcode}#over`}
          target="_blank"
          rel="noopener noreferrer">
          <span>{rowData.stock}</span>
        </Link>
        <ButtonIcon
          type={'secondary'}
          size={[15, 15]}
          icon="timeBack"
          onClick={() => handleHistoryClick(rowData)}
          height={HISTORY_BUTTON_SIZE}
          width={HISTORY_BUTTON_SIZE}
          htmlType="button"
          ariaLabel="open stock history"
        />
      </div>
    )
  }

  const renderDefaultCell = (rowIndex, form, productCombination) => (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Field
        type="select"
        name={`skuSection.productCombination[${rowIndex}].meanValue`}
        component="input">
        {({input}) => (
          <BaseCheckBox
            {...input}
            dataKey="meanValue"
            onChange={e => {
              if (e.target.checked) {
                form.batch(() => {
                  productCombination.forEach((_, idx) => {
                    if (idx !== rowIndex) {
                      form.change(
                        `skuSection.productCombination[${idx}].meanValue`,
                        false
                      )
                    }
                  })
                  form.change(
                    `skuSection.productCombination[${rowIndex}].meanValue`,
                    true
                  )
                })
              } else {
                input.onChange(e)
              }
            }}
          />
        )}
      </Field>
    </div>
  )

  const skuTableConfig = {
    columns: [
      {
        type: 'custom',
        field: 'meanValue',
        header: 'Default',
        align: 'center',
        body: (_, rowIndex) =>
          renderDefaultCell(rowIndex, form, productCombination)
      },
      {
        field: 'reference',
        header: 'Reference',
        align: 'flex-start'
      },
      {
        field: 'barcode',
        header: 'EAN',
        align: 'flex-start'
      },
      {
        field: 'color.colorId',
        header: 'Color',
        type: 'custom',
        body: rowData => renderColorCell(rowData)
      },
      {
        field: 'productWidthId',
        header: 'Width',
        align: 'center',
        type: 'custom',
        body: rowData => renderProductWidthCell(rowData)
      },
      {
        field: 'sizeId',
        header: 'Size',
        align: 'center',
        type: 'custom',
        body: rowData => renderSizeCell(rowData)
      },
      {
        field: 'stock',
        header: 'Stock',
        align: 'center',
        type: 'custom',
        body: rowData => renderStockCell(rowData)
      },
      {
        type: 'custom',
        field: 'active',
        header: 'Online Store',
        align: 'center',
        body: (_, rowIndex) => renderOnlineStoreCell(rowIndex)
      }
    ],
    footer: false,
    scroll: {
      scrollable: false
    },
    editrow: {
      hasEdit: false
    }
  }

  return {
    historyModalState,
    handleModalClose,
    skuTableConfig,
    productCombination
  }
}
