// index.js
import React from 'react'

const Index = ({data}) => {
  const handleValue = () => {
    if (data > 50) {
      return <span style={{color: '#50CFBD'}}>{data}</span>
    } else {
      return <span style={{color: '#F06548'}}>{data}</span>
    }
  }

  return <div style={{textAlign: 'center'}}>{handleValue()}</div>
}

export default Index
