import {useState} from 'react'

import UiTabs from '../../../../Components/Common/Tabs'
import Properties from './properties-tab/Properties'
import SKUTab from './sku-tab/SKUTab'

const tabs = [
  {id: 'properties', title: 'Properties'},
  {id: 'sku', title: 'SKU'},
  {id: 'composition', title: 'Composition'},
  {id: 'measure', title: 'Measure'},
  {id: 'price', title: 'Price'},
  {id: 'onlineStore', title: 'Online Store'},
  {id: 'picture', title: 'Picture'}
]

const ArticleDetailsTab = () => {
  const [activeTab, setActiveTab] = useState(tabs[0].id)

  const renderTabContent = () => {
    switch (activeTab) {
      case 'properties':
        return <Properties />
      case 'sku':
        return <SKUTab />
      case 'composition':
        return <div>Composition Content</div>
      case 'measure':
        return <div>Measure Content</div>
      case 'price':
        return <div>Price Content</div>
      case 'onlineStore':
        return <div>Online Store Content</div>
      case 'picture':
        return <div>Picture Content</div>
      default:
        return <div>Default Content</div>
    }
  }

  return (
    <div className="article-details-tab-container">
      <UiTabs tab={activeTab} setTab={setActiveTab} options={tabs} />
      {renderTabContent()}
    </div>
  )
}

export default ArticleDetailsTab
