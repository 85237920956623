import {useEffect, useState} from 'react'

import {Combobox, Pill, PillsInput, useCombobox} from '@mantine/core'
import {useTranslation} from 'react-i18next'
import {Label} from 'reactstrap'

const TextPillsInput = ({
  label,
  required,
  subtitle,
  hasCloseOnOption,
  placeholder,
  onChange
}) => {
  const {t} = useTranslation()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active')
  })
  const [search, setSearch] = useState('')
  const [data, setData] = useState([])
  const [currentValue, setCurrentValue] = useState([])
  const exactOptionMatch = data.some(item => item === search)

  useEffect(() => {
    onChange(currentValue)
  }, [currentValue])

  const handleValueSelect = val => {
    setSearch('')

    if (val === '$create') {
      if (!data.includes(search)) {
        setData(current => [...current, search])
        setCurrentValue(current => [...current, search])
      }
    }
  }

  const handleValueRemove = val => {
    setCurrentValue(current => current.filter(v => v !== val))
  }

  const values = currentValue.map((item, index) => (
    <Pill
      key={index}
      onRemove={() => handleValueRemove(item)}
      withRemoveButton={hasCloseOnOption ? true : false}
      className={
        hasCloseOnOption
          ? 'multiple-select-pill-with-x'
          : 'multiple-select-pill'
      }>
      {item}
    </Pill>
  ))

  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}>
        <Combobox.DropdownTarget>
          <PillsInput>
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={placeholder}
                  autoCorrect={false}
                  spellCheck={false}
                  autoComplete={false}
                  onChange={event => {
                    combobox.updateSelectedOptionIndex()
                    setSearch(event.currentTarget.value)
                  }}
                  onKeyDown={event => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault()
                      handleValueRemove(currentValue[currentValue.length - 1])
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown style={{display: 'none'}}>
          <Combobox.Options>
            {!exactOptionMatch && search.trim().length > 0 && (
              <Combobox.Option value="$create" selected={true}>
                + {t('CREATE')} {search}
              </Combobox.Option>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {subtitle && (
        <div style={{marginTop: 5}} className="bodyText_11 input_subtitle_text">
          {subtitle}
        </div>
      )}
    </div>
  )
}

export default TextPillsInput
